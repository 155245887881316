<ng-container *ngrxLet="translation$ as tr">
  <div class="terms-and-condition-container">
    <div class="terms-and-condition-header title-medium-m">{{tr?.['terms_and_conditions_label']}}</div>
    <div class="terms-and-condition-body body-regular-m">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
      standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a
      type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
      Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions
      of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
      industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
      it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
      typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
      containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
      versions of Lorem Ipsum.
    </div>
    <div class="terms-and-condition-body-footer">
      <p-button class="p-button-primary-outline p-button-md" (click)="onDecline()">Decline</p-button>
      <p-button class="p-button-md" (click)="onAccept()">Accept</p-button>
    </div>
  </div>
</ng-container>