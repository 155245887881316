import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, from, map, switchMap } from 'rxjs';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {

  public $refreshToken = new Subject<boolean>;

  constructor(private readonly authApi: AuthApiService) {
    this.$refreshToken.subscribe((res: any) => {
      const encryptedRefreshToken = localStorage.getItem('refresh_token');
      this.authApi.refreshToken({ refresh_token: encryptedRefreshToken! })
    })
  }

  private getDerivedKey(salt: Uint8Array): Observable<CryptoKey> {
    const importedKeyPromise = crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode('O'),
      'PBKDF2',
      false,
      ['deriveKey']
    );

    const derivedKeyPromise = importedKeyPromise.then(importedKey =>
      crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          salt: salt,
          iterations: 1,
          hash: 'SHA-512',
        },
        importedKey,
        { name: 'AES-GCM', length: 128 },
        true,
        ['encrypt', 'decrypt']
      )
    );

    return from(derivedKeyPromise);
  }

  private encryptValue(plainText: string): Observable<string> {
    const salt = crypto.getRandomValues(new Uint8Array(1));
    const iv = crypto.getRandomValues(new Uint8Array(1));

    return this.getDerivedKey(salt).pipe(
      switchMap(derivedKey =>
        from(
          crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: iv },
            derivedKey,
            new TextEncoder().encode(plainText)
          )
        )
      ),
      map(encryptedMessageBuffer => {
        const cipherByte = new Uint8Array([...salt, ...iv, ...new Uint8Array(encryptedMessageBuffer)]);
        return btoa(String.fromCharCode.apply(null, cipherByte as any));
      })
    );
  }

  private decryptValue(encryptedText: string): Observable<string> {
    const cipherByte = new Uint8Array(atob(encryptedText).split('').map(char => char.charCodeAt(0)));

    // Extract salt, IV, and the encrypted message
    const salt = cipherByte.slice(0, 1);
    const iv = cipherByte.slice(1, 2);
    const encryptedMessage = cipherByte.slice(2);

    return this.getDerivedKey(salt).pipe(
      switchMap(derivedKey =>
        from(
          crypto.subtle.decrypt(
            { name: 'AES-GCM', iv: iv },
            derivedKey,
            encryptedMessage
          )
        )
      ),
      map(decryptedMessageBuffer => {
        return new TextDecoder().decode(decryptedMessageBuffer);
      })
    );
  }

  encryptLoginParams(login: string, password: string): Observable<{ encryptedLogin: string; encryptedPassword: string }> {
    const encryptedLogin$ = login ? this.encryptValue(login) : from(Promise.resolve(''));
    const encryptedPassword$ = password ? this.encryptValue(password) : from(Promise.resolve(''));

    return forkJoin({
      encryptedLogin: encryptedLogin$,
      encryptedPassword: encryptedPassword$
    });
  }

  encryptToken(token: string): Observable<{ encryptedToken: string }> {
    const encryptedToken$ = token ? this.encryptValue(token) : from(Promise.resolve(''));

    return forkJoin({
      encryptedToken: encryptedToken$
    });
  }

  decryptToken(encryptedToken: string): Observable<{ decryptedToken: string }> {
    const decryptedToken$ = encryptedToken ? this.decryptValue(encryptedToken) : from(Promise.resolve(''));

    return forkJoin({
      decryptedToken: decryptedToken$
    });
  }
}