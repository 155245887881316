<ng-container *ngrxLet="translation$ as tr">
  <div class="plan-details">
    <div class="plan-information">
      <div class="section-title body-medium-l">
        <div class="body-medium-l">{{tr?.['manage_subscriptions_plan_label']}}</div>
        @if(planInfo.is_paid){
        <div>
          <p-badge [value]="tr?.['paid_label']" class="p-badge-success-outline" severity="success" />
        </div>
        }@else{
        <div>
          <p-badge [value]="tr?.['unpaid_label']" class="p-badge-danger-outline" severity="danger" />
        </div>
        }

      </div>
      <div class="plan-card-container">
        <div class="plan-card">
          <div class="plan-card-header">
            <div class="title-regular-m">{{planInfo.name}}</div>
          </div>
          <div class="plan-body-card">
            <div class="plan-specifications-container">
              @for (item of planInfo.plan_specifications; track $index) {
              <div class="plan-specifications-item">
                <div class="name body-regular-m">{{ item.name }}</div>
                <div class="value-unit body-medium-l">{{ item.value }} {{item.quantity_unit}}</div>
              </div>
              }
            </div>
            <div class="vat-valid-container">
              <div class="vat">
                <div class="vat-lable body-regular-xs">{{tr?.['vat_included_label']}}</div>
                <div class="vat-value display-bold-s">{{planInfo.price.amount_inclusive_vat}} <br />
                  <span>{{tr?.['bill_payment_omr_label']}}</span>
                </div>
              </div>
              <div class="valid-for">
                <div class="valid-for-lable body-regular-xs">{{tr?.['valid_for_label']}}</div>
                <div class="valid-for-value display-bold-s">{{planInfo.valid_for}}
                  <span>{{planInfo.time}}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="plan-card-footer">

          <div class="items-from">
            <div class="other-form-items">
              @if(planInfo.recharge_amount){
              <div class="form-item">
                <label for="">{{tr?.['recharge_amount_label']}}</label>
                <input type="text" pInputText [disabled]="true" [value]="planInfo.recharge_amount">
              </div>
              }
              @if(planInfo.total_due_amount && planInfo.recharge_amount){
              <div class="form-item">
                <label for="">{{tr?.['total_due_amount_text_field']}}</label>
                <input type="text" pInputText [disabled]="true" [value]="planInfo.total_due_amount">
              </div>
              }
              @if(planInfo.installment_amount){
              <div class="form-item">
                <label for="">{{tr?.['installment_amount_label']}}</label>
                <input type="text" pInputText [disabled]="true" [value]="planInfo.installment_amount">
              </div>
              }
              @if(planInfo.vanity_amount){
              <div class="form-item">
                <label for="">{{tr?.['vanity_omr_label']}}</label>
                <input type="text" pInputText [disabled]="true" [value]="planInfo.vanity_amount">
              </div>
              }
              @if(planInfo.deposit_amount){
              <div class="form-item">
                <label for="">{{tr?.['deposit_omr_label']}}</label>
                <input type="text" pInputText [disabled]="true" [value]="planInfo.deposit_amount">
              </div>
              }
              @if(planInfo.total_due_amount && planInfo.installment_amount){
              <div class="form-item">
                <label for="">{{tr?.['due_vat_label']}}</label>
                <input type="text" pInputText [disabled]="true" [value]="planInfo.total_due_amount">
              </div>
              }
            </div>
            @if(planInfo.salary_range_description){
            <div class="form-item">
              <label for="">{{tr?.['salary_range_label']}}</label>
              <input type="text" pInputText [disabled]="true" [value]="planInfo.salary_range_description">
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>