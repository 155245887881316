import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { reducers } from '../store';
import { provideEffects } from '@ngrx/effects';
import { AuthEffect } from '../store/auth/effects.auth';
import { BaseEffect } from '../store/base/effects.base';
import { provideRouterStore } from '@ngrx/router-store';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { routes } from './app.routes';
import { loaderInterceptor } from './core/loader/loader.interceptor';
import { FulfillmentEffects } from '../store/fulfilment/effects.fulfilment';
import { authInterceptor } from './core/auth/services/auth.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideStore(reducers),
    provideEffects(AuthEffect, BaseEffect, FulfillmentEffects),
    provideStoreDevtools({
      maxAge: 4, // Retains last 25 states
      logOnly: true, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    provideRouterStore(),
    provideHttpClient(withInterceptors([loaderInterceptor, authInterceptor])),
    provideAnimationsAsync(),
    provideHttpClient()
  ]
};
