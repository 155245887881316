import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-term-and-condition-modal',
  standalone: true,
  templateUrl: './term-and-condition-modal.component.html',
  styleUrls: ['./term-and-condition-modal.component.scss'],
  imports: [CommonModule, LetDirective, ButtonModule, NgbModalModule]
})
export class TermAndConditionModalComponent extends BaseComponent {
  @Output() decline = new EventEmitter<void>();
  @Output() accept = new EventEmitter<void>();

  constructor(
    override store: Store,
    public activeModal: NgbActiveModal
  ) {
    super(store);
  }


  onDecline() {
    this.decline.emit();
    this.activeModal.close();
  }

  onAccept() {
    this.accept.emit();
    this.activeModal.close();
  }
}
