<header>
  <div class="content">
    <button type="button" class="btn-bars" mat-button (click)="onToggleDrawer()">
      <i class="fa-solid fa-bars-staggered"></i>
    </button>

    @if (drawerMode === 'over') {
    <div>
      <img src="../../../assets/images/Logo.png" alt="Logo" />
    </div>
    }
    <button type="button" placement="bottom" [ngbPopover]="popContent" container="body"
      popoverClass="user-section-popover" class="profile-user d-flex gap-2 align-items-center"
      aria-label="User profile">
      <p class="m-0 body-medium-s">Hi, <span>{{ userName }}</span></p>
      <p class="m-0"><i class="fa-duotone fa-solid fa-user"></i></p>
    </button>

    <ng-template #popContent>
      <div class="popover-container">
        <div class="popover-item drop-down-item" (click)="logout()">
          <em class="fa-regular fa-arrow-right-from-bracket"></em>
          <label class="body-regular-m" for="">Logout</label>
        </div>
      </div>
    </ng-template>

  </div>
</header>