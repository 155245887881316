import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Store } from '@ngrx/store';
import { LetDirective } from '@ngrx/component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { IContactInfo } from '../../interfaces/contact-info.interface';

@Component({
  selector: 'app-add-contact',
  standalone: true,
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
  imports: [CommonModule, LetDirective, InputTextModule, CheckboxModule, FormsModule],
  encapsulation: ViewEncapsulation.None
})
export class AddContactComponent extends BaseComponent {
  @Input() contactInfo!: IContactInfo;

  isBillingEmailChecked = true; // Set to true to make it checked by default

  constructor(override store: Store) {
    super(store);
  }


}
