import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'app-contract',
  standalone: true,
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
  imports: [CommonModule, LetDirective]
})
export class ContractComponent {
  @Output() showContract: EventEmitter<void> = new EventEmitter<void>();

  constructor(private modalService: NgbModal) { }

  onShowContract() {
    this.showContract.emit();
  }
}
