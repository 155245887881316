import { Pipe, PipeTransform } from "@angular/core";
import { LookupArray, SystemLookup } from "../interfaces/systemLookups.interface";

@Pipe({
    name: 'extractLookup',
    standalone: true
})
export class LookupsExtractPipe implements PipeTransform {

    transform(value: SystemLookup[] | undefined, lookupId: string): LookupArray[] {
        if (!value) {
            return [{
                name: 'No lookups founds',
                key: 'No lookups founds'
            }]
        }

        let filteredLookups: LookupArray[] = []
        const lookups = value.find((lookup) => lookup.id === lookupId);

        if (lookups) {
            filteredLookups = lookups.array;
        } else {
            filteredLookups = [{
                name: 'No lookups founds',
                key: 'No lookups founds'
            }]
        }
        return filteredLookups;
    }

}