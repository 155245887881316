import { createFeature, createReducer, on } from '@ngrx/store';
import * as authActions from './actions.auth'
import { ILoginResponse } from '../../app/core/auth/interfaces/auth.interface';

export interface AuthState {
  loginResponse: ILoginResponse | null,
  refreshToken: string | null;
}

const initialAuthState: AuthState = {
  loginResponse: null,
  refreshToken: null,
};

export const authReducer = createFeature(
  {
    name: 'authReducer',
    reducer: createReducer(
      initialAuthState,
      on(authActions.updateLocalAuthState, (state, { key, value }) => ({
        ...state,
        [key]: value
      })),
      on(authActions.basicLoginActions.loginSuccess,
        (state, { loginResponse }): AuthState => ({ ...state, loginResponse })),
      on(authActions.setRefreshTokenAction,
        (state, { refreshToken }): AuthState => ({ ...state, refreshToken })),
    )
  }
);