import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, tap, filter } from 'rxjs';
import { postpaidPortInActions } from '../../../../../store/fulfilment/actions.fulfilment';
import { selectPostpaidPortInDetails } from '../../../../../store/fulfilment/selectors.fulfilment';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'app-postpaid-portIn',
  templateUrl: './postpaid-portIn.page.html',
  styleUrls: ['./postpaid-portIn.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LetDirective
  ],
  encapsulation: ViewEncapsulation.None
})
export class PostpaidPortInPage {

  routerParams$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    map(id => id ? +id : null),
    tap(id => {
      if (id !== null) {
        this.store.dispatch(postpaidPortInActions.getPostpaidPortInDetails({ id }));
      }
    })
  );

  selectorPostpaidPortInDetailsData$ = this.store.select(selectPostpaidPortInDetails).pipe(
    filter(res => res !== null)
  )

  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) { }

}
