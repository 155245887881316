import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from 'primeng/button';
import { AuthService } from '../../auth/services/auth.service';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [MatSidenavModule, ButtonModule, NgbModule],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  @Input() drawerMode!: MatDrawerMode;
  @Output() toggleDrawer = new EventEmitter<void>();

  userName: string;

  constructor(private readonly authService: AuthService) {
    this.userName = this.authService.getUserName();
  }

  onToggleDrawer() {
    this.toggleDrawer.emit();
  }

  logout() {
    this.authService.logout();
  }
}
