import { Routes } from '@angular/router';
import { LoginPage } from './core/auth/pages/login/login.page';
import { appContainerRoutes } from './core/app-container/app-container.routes';
import { LoginGuard } from './core/auth/guards/login.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';

export const routes: Routes = [
  ...appContainerRoutes,
  {
    path: 'login',
    component: LoginPage,
    canActivate: [LoginGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  }
];
