import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISIMInfo } from '../../interfaces/sim-info.interface';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Store } from '@ngrx/store';
import { LetDirective } from '@ngrx/component';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-sim-info',
  standalone: true,
  templateUrl: './sim-info.component.html',
  styleUrls: ['./sim-info.component.scss'],
  imports: [CommonModule, LetDirective, InputTextModule]
})
export class SimInfoComponent extends BaseComponent {
  @Input() simInfo!: ISIMInfo;
  @Output() iccidChange = new EventEmitter<string>();

  constructor(override store: Store) {
    super(store)
  }

  handleIccidInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const iccidValue = inputElement.value;
    this.iccidChange.emit(iccidValue);
  }
}
