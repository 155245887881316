<div class="modal-container">
  <div class="search-modal-header" *ngIf="fulfillmentRequests.length > 0">
    <p class="modal-title title-medium-m">Select MSISDN</p>
    <button type="button" class="search-btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <i class="fa-light fa-xmark"></i>
    </button>
  </div>

  <div>
    <div *ngIf="fulfillmentRequests.length > 0; else noResults" class="search-modal-body">
      <div *ngFor="let request of fulfillmentRequests">
        <div class="d-flex gap-1 align-items-center">
          <p-radioButton type="radio" [name]="'searchFulfilment'" [value]="request.msisdn"
            [inputId]="'searchFulfilment' + request.id" [(ngModel)]="selectedMsisdn"></p-radioButton>
          <label [for]="'searchFulfilment' + request.id" class="body-regular-m">
            {{ request.msisdn }} - {{ request.process_type_name }}
          </label>
        </div>
      </div>
    </div>

    <!-- No results template -->
    <ng-template #noResults>
      <div class="d-flex gap-1">
        <label class="body-regular-m" for="">No result found</label>
      </div>
    </ng-template>
  </div>

  <div class="search-modal-footer">
    <p-button class="p-button-primary-outline p-button-md" (click)="activeModal.close('Close click')"
      (keydown)="onClose($event)"> Close </p-button>

    <p-button class="p-button-md" *ngIf="fulfillmentRequests.length > 0" (click)="selectRequest()">Select</p-button>
  </div>
</div>