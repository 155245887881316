import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-footer-fulfilment',
  standalone: true,
  templateUrl: './footer-fulfilment.component.html',
  styleUrls: ['./footer-fulfilment.component.scss'],
  imports: [CommonModule, ButtonModule]
})
export class FooterFulfilmentComponent implements OnInit {
  @Output() submit: EventEmitter<void> = new EventEmitter<void>()

  constructor() { }

  ngOnInit() {
  }

  onSubmit() {
    this.submit.emit()
  }
}
