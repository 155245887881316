import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkEmptyObjectValues',
  standalone: true
})
export class CheckEmptyObjectValuesPipe implements PipeTransform {

  transform(value: object): any {
    const values = Object.values(value);
    const length = values.filter(x=> x.length)
    return length.length;
  }

}
