import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, tap, filter } from 'rxjs';
import { selectPrepaidPortInDetails } from '../../../../../store/fulfilment/selectors.fulfilment';
import { prepaidPortInActions } from '../../../../../store/fulfilment/actions.fulfilment';
import { LetDirective } from '@ngrx/component';
import { IOrganizePrepaidDetailsResponse, IPrepaidDetailsResponse } from '../../interfaces/prepaid-details.interface';
import { IPrepaidPortInResponse } from '../../interfaces/prepaid-portIn-details.interface';

@Component({
  selector: 'app-postpaid-portIn',
  templateUrl: './prepaid-portIn.page.html',
  styleUrls: ['./prepaid-portIn.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LetDirective
  ],
  encapsulation: ViewEncapsulation.None
})
export class PrepaidPortInPage {

  routerParams$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    map(id => id ? +id : null),
    tap(id => {
      if (id !== null) {
        this.store.dispatch(prepaidPortInActions.getPrepaidPortInDetails({ id }));
      }
    })
  );

  selectorPrepaidPortInDetailsData$ = this.store.select(selectPrepaidPortInDetails).pipe(
    filter(res => res !== null),
    map((res) => this.organizeDetails(res as IPrepaidPortInResponse))
  )

  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) { }

  organizeDetails(res: IPrepaidDetailsResponse): IOrganizePrepaidDetailsResponse {
    const object: IOrganizePrepaidDetailsResponse = {
      customer_info: {
        title: res?.title || '',
        first_name: res?.first_name || '',
        second_name: res?.second_name || '',
        middle_name: res?.middle_name || '',
        last_name: res?.last_name || '',
        id_type: res?.id_type || '',
        id_number: res?.id_number || '',
        id_expiry_date: res?.id_expiry_date || '',
        date_of_birth: res?.date_of_birth || '',
        nationality: res?.nationality || '',
        gender: res?.gender || ''
      },
      contact_info: {
        contact_number: res?.contact_number || '',
        email: res?.email || '',
        is_billing_email: res?.is_billing_email || false
      },
      sim_info: {
        msisdn: res?.msisdn || '',
        vanity_level: res?.vanity_level || '',
        vanity_amount: res?.vanity_amount || ''
      },
      plan_info: {
        is_paid: res?.is_paid || false,
        recharge_amount: res?.recharge_amount || '',
        total_due_amount: res?.total_due_amount || '',
        validate: res?.plan_details.validate || '',
        flexi_mins: res?.plan_details.flexi_mins || '',
        cross_mins: res?.plan_details.cross_mins || '',
        valid_for: res.plan_details?.valid_for || '',
        price: {
          amount: res.plan_details?.price?.amount || '',
          amount_inclusive_vat: res.plan_details?.price?.amount_inclusive_vat || '',
          vat_amount: res.plan_details?.price?.vat_amount || '',
          vat_percentage: res.plan_details?.price?.vat_percentage || '',
          vat_type: res.plan_details?.price?.vat_type || ''
        },
        name: res.plan_details?.name || '',
        data: res.plan_details?.data || '',
        time: res.plan_details?.time || '',
        plan_specifications: res.plan_details?.plan_specifications?.map(spec => ({
          quantity_unit: spec.quantity_unit || '',
          name: spec.name || '',
          value: spec.value || '',
          class_name: spec.class_name || ''
        })) || []
      }
    };

    return object;
  }

}
