/* eslint-disable @typescript-eslint/quotes */
import { routerReducer } from "@ngrx/router-store";
import { authReducer } from "./auth/reducer.auth";
import { baseReducer } from "./base/reducer.base";
import { fulfillmentReducer } from "./fulfilment/reducer.fulfilment";


export const reducers = {
    [authReducer.name]: authReducer.reducer,
    [baseReducer.name]: baseReducer.reducer,
    [fulfillmentReducer.name]: fulfillmentReducer.reducer,
    router: routerReducer
};


