<canvas class="d-none" #signatureCanvas id="signatureCanvas" width="400" height="200"
  style="border: 1px solid black;"></canvas>
<ng-container *ngrxLet="translation$ as tr">
  <div class="signature-section">
    <div class="title-medium-s">Customer Signature</div>
    @if(signatureImg.length){
    <div class="signature-base64-container">
      <img class="signature-base64" [src]="'data:image/png;base64, ' + signatureImg" alt="">
    </div>
    }
    <p-button class="sign-btn" severity="danger" (onClick)="Signature()" [text]="true">
      @if(!signatureImg.length){
      {{tr?.['Sign_btn_label'] ?? 'Sign'}}
      }@else {
      <em class="fa-sharp fa-light fa-rotate-right"></em>
      {{tr?.['signature_try_again_button']}}
      }
    </p-button>
  </div>
</ng-container>