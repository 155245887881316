<ng-container *ngrxLet="signature$"></ng-container>
<ng-container *ngrxLet="translation$ as tr">
  <div class="details-documents">
    <div class="title body-medium-l">{{tr?.['documents_label']}}</div>
    <div class="document-container">
      @if(!(documents | checkEmptyObjectValues)){
      <div class="document-viewer-empty">
        <em class="fa-light fa-file"></em>
        <div class="">{{tr?.['view_uploaded_file_label'] ?? 'View uploaded file'}}</div>
      </div>
      }
      @if((documents | checkEmptyObjectValues)){
      @if(documents[defaultPreview]){
      <div class="document-viewer">
        <p-image class="img-document" [src]="'data:image/png;base64, '+documents[defaultPreview]" alt=""
          [preview]="true" appendTo="body" />
      </div>
      } @else {
      <div class="document-viewer">
        {{tr?.['the_document_does_not_available_please_upload_one_label'] ??
        'The document does not available please upload one'}}
      </div>
      }
      }
      <div class="documents-actions">
        @if(documentTypes.length){
        <p-dropdown placeholder="document type" [options]="documentTypes" optionLabel="label"
          (onChange)="selectDocumentType($event)" [(ngModel)]="selectedDropdown"></p-dropdown>
        }

        <div class="buttons">
          <p-button class="p-button-md p-button-primary-outline" severity="primary"
            [disabled]="!selectedDropdown">
            <em class="fa-light fa-barcode-scan"></em>
            {{tr?.['scan_button'] ??
            'Scan'}}</p-button>
          <p-button class="p-button-md" severity="primary" (onClick)="fileUpload.choose()"
            [disabled]="!selectedDropdown">
            <em class="fa-light fa-plus"></em>
            {{tr?.['registration_upload_label']}}
          </p-button>
          <p-fileUpload [multiple]="false" (onSelect)="onFileSelected($event)" #fileUpload type="file" class="d-none" />
        </div>
      </div>
      @if((documents | checkEmptyObjectValues)){
      <div class="uploaded-documents">
        @if(documents['id_copy_front_base64']?.length){

        <div class="document" [ngClass]="{'active': defaultPreview === 'id_copy_front_base64'}"
          (click)="defaultPreview = 'id_copy_front_base64'">
          <div class="details">
            <!-- as example for icon -->
            <!-- <em class="fa-light fa-file-pdf"></em> -->
             <img [src]="('data:image/png;base64, '+documents['id_copy_front_base64'] | base64ToThumbnail: 40) | async" alt="">
            <div class="file-name body-medium-m">{{tr?.['id_copy_front_base64_label'] ?? 'Front ID'}}</div>
          </div>
          <!-- as example for delete -->
          <!-- <div class="actions">
              <button class="trash" (click)="deleteDocument('id_copy_front_base64')"><em class="fa-light fa-trash"></em></button>
            </div> -->
        </div>
        }

        @if(documents['id_copy_back_base64']?.length){
        <div class="document" [ngClass]="{'active': defaultPreview === 'id_copy_back_base64'}"
          (click)="defaultPreview = 'id_copy_back_base64'">
          <div class="details">
            <img [src]="('data:image/png;base64, '+documents['id_copy_back_base64'] | base64ToThumbnail: 40) | async" alt="">
            <div class="file-name body-medium-m">{{tr?.['id_copy_back_base64_label'] ?? 'Back ID'}}</div>
          </div>
        </div>
        }

        @if(documents['signature_base64']?.length){
        <div class="document" [ngClass]="{'active': defaultPreview === 'signature_base64'}"
          (click)="defaultPreview = 'signature_base64'">
          <div class="details">
            <img [src]="('data:image/png;base64, '+documents['signature_base64'])" width="40px" height="40px" alt="">
            <div class="file-name body-medium-m">{{tr?.['signature_base64_label'] ?? 'Signature'}}</div>
          </div>
        </div>
        }

        @if(documents['sad_document_base64']?.length){
        <div class="document" [ngClass]="{'active': defaultPreview === 'sad_document_base64'}"
          (click)="defaultPreview = 'sad_document_base64'">
          <div class="details">
            <img [src]="('data:image/png;base64, '+documents['sad_document_base64'] | base64ToThumbnail: 40) | async" alt="">
            <div class="file-name body-medium-m">{{tr?.['sad_document_base64_label'] ?? 'SAD'}}</div>
          </div>
        </div>
        }
      </div>
      }
    </div>
  </div>
</ng-container>
