<ng-container *ngrxLet="translation$ as tr">
  <div class="login-container">
    <div class="gradient">
      <div class="planet"></div>
    </div>
    <div class="gradient-second">
      <div class="planet-second"></div>
    </div>
    <div class="login-section container">
      <div class="logo">
        <img src="../../../../../assets/images/Logo.png" alt="Logo" />
      </div>
      <div class="welcoming-msg">
        <p class="m-0">{{tr?.['welcome_to_label']}}</p>
        <h2>{{tr?.['salespoint_web_channel_label'] ?? 'Sales point Web Channel'}}</h2>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="submit()" class="login-form w-100">
        <div class="inner-form w-100 d-grid gap-3">
          <div class="form-item">
            <label for="login">{{tr?.['login_user_name_text_field']}}</label>
            <input type="text" pInputText formControlName="login"
              [placeholder]="tr?.['login_user_name_input_placeholder']?? 'translation not found'" />
          </div>
          <div class="form-item">
            <label for="password">{{tr?.['login_password_text_field']}}</label>
            <input formControlName="password" type="password"
              [placeholder]="tr?.['login_password_input_placeholder']?? 'translation not found'" />
          </div>
        </div>
        <div class="w-100">
          <p-button class="btn-login w-100" type="submit" severity="primary"
            [label]="tr?.['login_button'] ?? 'Login'" />
        </div>
      </form>
      <div class="credential">
        <div class="line-1"></div>
        <div class="or">{{tr?.['or_label'] ?? 'OR'}}</div>
        <div class="line-2"></div>
      </div>
      <div class="ldap">
        <img class="logo2" src="../../../../../assets/images/Logo-icon.png" alt="Logo-icon" />
        <div class="ooredoo-ad body-medium-l">{{tr?.['ooredoo_ad_label'] ?? 'Ooredoo AD'}}</div>
      </div>
    </div>
  </div>
</ng-container>