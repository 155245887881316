import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Store } from '@ngrx/store';
import { CheckboxModule } from 'primeng/checkbox';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermAndConditionModalComponent } from '../term-and-condition-modal/term-and-condition-modal.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  imports: [CommonModule, LetDirective, CheckboxModule, FormsModule]
})
export class TermsAndConditionsComponent extends BaseComponent {
  checked = false;
  showValidationMessage = false;

  constructor(
    override store: Store,
    private modalService: NgbModal
  ) {
    super(store);
  }

  openModal() {
    const modalRef = this.modalService.open(TermAndConditionModalComponent, {
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.decline.subscribe(() => {
      this.checked = false;
    });

    modalRef.componentInstance.accept.subscribe(() => {
      this.checked = true;
    });
  }

  validateTerms() {
    if (!this.checked) {
      this.showValidationMessage = true;
    } else {
      this.showValidationMessage = false;
    }
  }

}
