import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { ISystemLookupResponse } from '../../app/shared/interfaces/systemLookups.interface';
import { ISystemSettingsResponse } from '../../app/shared/interfaces/systemSettings.interface';
import { ITranslationResponse } from '../../app/shared/interfaces/translation.interface';
import { IUpdateLocalStatePayload } from '../interfaces/updateLocalState.interface';
import { BaseState } from './reducer.base';

export const updateLocalBaseState = createAction(
  '[BASE] update local base state',
  props<IUpdateLocalStatePayload<BaseState>>()
)

export const basicLoginActions = createActionGroup({
  source: 'BASE',
  events: {
    'try login': props<{ userName: string; password: string }>(),
    'login success': props<{ res: any }>(),
    'login failure': props<{ error: Error }>(),
  }
});

export const systemLookupActions = createActionGroup({
  source: 'BASE',
  events: {
    'get system lookups': emptyProps(),
    'get system lookups success': props<{ response: ISystemLookupResponse }>(),
    'get system lookups failure': props<{ error: Error }>(),
  }
});
export const systemSettingsActions = createActionGroup({
  source: 'BASE',
  events: {
    'get system settings': emptyProps(),
    'get system settings success': props<{ response: ISystemSettingsResponse }>(),
    'get system settings failure': props<{ error: Error }>(),
  }
});

export const translationActions = createActionGroup({
  source: 'BASE',
  events: {
    'get translation': emptyProps(),
    'get translation success': props<{ response: {[key: string]: string} }>(),
    'get translation failure': props<{ error: Error }>(),
  }
});






