<ng-container *ngrxLet="breakPointObserver$"></ng-container>
<div class="main-page">
  <mat-drawer-container>
    <mat-drawer #drawer [mode]="sideNavMode" [opened]="true">
      <app-left-side-menu [drawer]="drawer"></app-left-side-menu>
    </mat-drawer>
    <div #mat_content class="app-container-content">
      <app-header [drawerMode]="sideNavMode" (toggleDrawer)="toggleDrawer()"></app-header>
      <div class="app-container-outlet">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-drawer-container>
</div>
