import { Component, Input } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { LetDirective } from '@ngrx/component';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Store } from '@ngrx/store';
import { ICustomerInfo } from '../../interfaces/customer-info.interface';

@Component({
  selector: 'app-customer-info',
  standalone: true,
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
  imports: [
    InputTextModule,
    LetDirective
  ]
})
export class CustomerInfoComponent extends BaseComponent {

  @Input({ required: true }) information!: ICustomerInfo;

  constructor(override store: Store) {
    super(store)
  }

  formatDate(dateString: string): string {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }
}

