import { createFeature, createReducer, on } from '@ngrx/store';
import { systemLookupActions, systemSettingsActions, translationActions, updateLocalBaseState } from './actions.base';
import { ISystemLookupResponse } from '../../app/shared/interfaces/systemLookups.interface';
import { ISystemSettingsResponse } from '../../app/shared/interfaces/systemSettings.interface';

export interface BaseState {
  systemLookups: ISystemLookupResponse | null;
  systemSettings: ISystemSettingsResponse | null;
  translation: { [key: string]: string } | null;
}

const initialBaseState: BaseState = {
  systemLookups: null,
  systemSettings: null,
  translation: null,
};

export const baseReducer = createFeature(
  {
    name: 'baseReducer',
    reducer: createReducer(
      initialBaseState,
      on(updateLocalBaseState, (state, { key, value }) => ({
        ...state,
        [key]: value
      })),
      on(systemLookupActions.getSystemLookupsSuccess, (state, { response }): BaseState => ({
        ...state,
        systemLookups: response,
      })),
      on(systemSettingsActions.getSystemSettingsSuccess, (state, { response }): BaseState => ({
        ...state,
        systemSettings: response,
      })),
      on(translationActions.getTranslationSuccess, (state, { response }): BaseState => ({
        ...state,
        translation: response,
      }))
    )
  }
);
