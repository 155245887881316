/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as baseActions from './actions.base';
import { SharedApiService } from '../../app/shared/services/shared-api.service';
@Injectable()
export class BaseEffect implements OnInitEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private sharedApiService: SharedApiService,
  ) { }

  ngrxOnInitEffects(): Action {
    this.store.dispatch(baseActions.translationActions.getTranslation())
    this.store.dispatch(baseActions.systemSettingsActions.getSystemSettings())
    return baseActions.systemLookupActions.getSystemLookups();
  }

  getSystemLookups$ = createEffect(() => this.actions$.pipe(
    ofType(baseActions.systemLookupActions.getSystemLookups),
    switchMap(() => this.sharedApiService.getSystemLookups()),
    map(response => baseActions.systemLookupActions.getSystemLookupsSuccess({ response })),
    catchError((error: any) => of(baseActions.systemLookupActions.getSystemLookupsFailure({ error })))
  ));

  getSystemSettings$ = createEffect(() => this.actions$.pipe(
    ofType(baseActions.systemSettingsActions.getSystemSettings),
    switchMap(() => this.sharedApiService.getSystemSettings()),
    map(response => baseActions.systemSettingsActions.getSystemSettingsSuccess({ response })),
    catchError((error: any) => of(baseActions.systemSettingsActions.getSystemSettingsFailure({ error })))
  ));

  getTranslation$ = createEffect(() => this.actions$.pipe(
    ofType(baseActions.translationActions.getTranslation),
    switchMap(() => this.sharedApiService.translation()),
    map(({Translations}) => baseActions.translationActions.getTranslationSuccess({ response: Translations})),
    catchError((error: any) => of(baseActions.translationActions.getTranslationFailure({ error })))
  ));
}
