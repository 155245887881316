import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILoginRequest, ILoginResponse, IRefreshToken } from '../interfaces/auth.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiHeaderService } from '../../../shared/services/api-headers.service';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  baseUrl: string = ''
  constructor(private readonly http: HttpClient, private readonly apiHeader: ApiHeaderService) {
    this.baseUrl = environment.baseApiUrl
  }

  onLogin(body: ILoginRequest): Observable<ILoginResponse> {
    const _baseUrl = this.baseUrl + '/api/Core/SalesPoint/v3.0/Login';
    const headers = this.apiHeader.postAuthHeaders();
    const string = JSON.stringify(body)
    return this.http.post<ILoginResponse>(_baseUrl, string, { headers: headers, withCredentials: true })
  }

  refreshToken(body: IRefreshToken): Observable<ILoginResponse> {
    const _baseUrl = this.baseUrl + '/api/Core/SalesPoint/v3.0/RefreshToken';
    const headers = this.apiHeader.postPublicHeaders();
    const string = JSON.stringify(body)
    return this.http.post<ILoginResponse>(_baseUrl, string, { headers: headers, withCredentials: true })
  }

}