import { createFeature, createReducer, on } from "@ngrx/store";
import { IPostpaidDetailsResponse } from "../../app/features/fulfilment/interfaces/postpaid-details.interface";
import { IPrepaidDetailsResponse } from "../../app/features/fulfilment/interfaces/prepaid-details.interface";
import { ISimReplacementDetailsResponse } from "../../app/features/fulfilment/interfaces/sim-replacement-details.interface";
import * as fulfillmentActions from './actions.fulfilment';
import { IPrepaidPortInResponse } from "../../app/features/fulfilment/interfaces/prepaid-portIn-details.interface";
import { IPostpaidPortInResponse } from "../../app/features/fulfilment/interfaces/postpaid-portIn-details.interface";

export interface FulfillmentState {
  simReplacementDetails: ISimReplacementDetailsResponse | null;
  postpaidDetails: IPostpaidDetailsResponse | null;
  prepaidDetails: IPrepaidDetailsResponse | null;
  postpaidPortInDetails: IPostpaidPortInResponse | null;
  prepaidPortInDetails: IPrepaidPortInResponse | null;
}

export const initialFulfillmentState: FulfillmentState = {
  simReplacementDetails: null,
  postpaidDetails: null,
  prepaidDetails: null,
  postpaidPortInDetails: null,
  prepaidPortInDetails: null,
};

export const fulfillmentReducer = createFeature({
  name: 'fulfillment',
  reducer: createReducer(
    initialFulfillmentState,
    on(fulfillmentActions.updateLocalFulfilmentState, (state, { key, value }) => ({
      ...state,
      [key]: value
    })),
    on(fulfillmentActions.postpaidDetailsActions.getPostpaidDetailsSuccess, (state, { response }) => ({
      ...state,
      postpaidDetails: response,
    })
    ),
    on(fulfillmentActions.prepaidDetailsActions.getPrepaidDetailsSuccess, (state, { response }) => ({
      ...state,
      prepaidDetails: response,
    })),
    on(fulfillmentActions.simReplacementDetailsActions.getSimReplacementDetailsSuccess, (state, { response }) => ({
      ...state,
      simReplacementDetails: response,
    })),
    on(fulfillmentActions.postpaidPortInActions.getPostpaidPortInDetailsSuccess, (state, { response }) => ({
      ...state,
      postpaidPortInDetails: response,
    })),
    on(fulfillmentActions.prepaidPortInActions.getPrepaidPortInDetailsSuccess, (state, { response }) => ({
      ...state,
      prepaidPortInDetails: response,
    }))
  )
});
