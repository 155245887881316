import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { filter, tap } from 'rxjs/operators';
import { LetDirective } from '@ngrx/component';
@Component({
  selector: 'app-left-side-menu',
  standalone: true,
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.scss'],
  imports: [CommonModule, SidebarModule, ButtonModule, LetDirective],
})
export class LeftSideMenuComponent implements OnInit {
  @Input() drawer!: MatDrawer;

  sidebarVisible: boolean = false;
  siteNavList: any;
  selectedIndex: number = -1;
  selectedRoute: string = '';

  navigationEnd$ = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      tap(x => { this.updateActiveLink() })
    );

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.siteNavList = [
      {
        icon: '<i class="fa-regular fa-sim-card"></i>',
        name: 'Digital fulfilment',
        link: 'home/fulfilment'
      },
      {
        icon: '<i class="fa-solid fa-arrow-down-to-square"></i>',
        name: 'Port In',
        link: 'home/port-in'
      },
      {
        icon: '<i class="fa-regular fa-building"></i>',
        name: 'Digital B2B',
        link: 'home/digital-b2b'
      },
    ];

    this.updateActiveLink();
  }

  updateActiveLink() {
    const currentRoute = this.router.url;
    this.selectedRoute = this.siteNavList.find((item: any) => currentRoute.includes(item.link))?.link;
  }

  onMenuItemClick(item: any) {
    this.router.navigate([item.link]);
  }

  navigateToLandingPage() {
    this.router.navigate(['home/landing-page']);
  }
}
