import { versionDate } from './versionDate';

export const environment = {
  production: true,
  baseApiUrl: 'https://oodev.salespoint.live',
  angularLink: '',
  domain: '',
  apiKey: 'c2FsZXNwb2ludF9kZmdramc=',
  versionDate
};
