<ng-container *ngrxLet="translation$ as tr">
  <div class="box-alert">
    @if(statusCode == 200){
    <div class="d-flex justify-content-center w-100">
      <div class="icon-circle icon-circle-success">
        <div class="second-circle-success"><i class="fa-sharp fa-solid fa-circle-check"></i></div>
      </div>
    </div>
    <div class="title-alert-popup title-medium-m w-100">Success</div>
    }@else if(statusCode == 400){
    <div class="d-flex justify-content-center w-100">
      <div class="icon-circle icon-circle-failure">
        <div class="second-circle second-circle-failure"><i class="fa-sharp fa-solid fa-circle-check"></i></div>
      </div>
    </div>
    <div class="title-alert-popup title-medium-m w-100">Failure</div>
    }
    <div class="msg-alert-popup body-regular-l w-100">{{responseMessage}}</div>
    <div class="btn-alert-popup w-100">
      <p-button class="p-button-md w-100"
        (click)="activeModal.close('Close click')">{{tr?.['alert_ok_title_button']}}</p-button>
    </div>
  </div>
</ng-container>