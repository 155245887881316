import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { BaseComponent } from '../../../../shared/components/base.component';
import { LetDirective } from '@ngrx/component';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { Observable, take, tap } from 'rxjs';
import { ImageModule } from 'primeng/image';
import { CheckEmptyObjectValuesPipe } from '../../pipes/checkEmptyObjectValues.pipe';
import { IDocument } from '../../interfaces/documents.interface';
import { FormsModule } from '@angular/forms';
import { Base64ToThumbnailPipe } from '../../../../shared/pipes/base64ToThumbnail.pipe';
import { ofType } from '@ngrx/effects';
import { setImageBase64Data, updateSignatureInDocumentDetails } from '../../../../../store/fulfilment/actions.fulfilment';

@Component({
  selector: 'app-details-document',
  templateUrl: './details-document.component.html',
  styleUrls: ['./details-document.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    DropdownModule,
    ButtonModule,
    FileUploadModule,
    ImageModule,
    CheckEmptyObjectValuesPipe,
    FormsModule,
    Base64ToThumbnailPipe
  ],
  encapsulation: ViewEncapsulation.None
})
export class DetailsDocumentComponent extends BaseComponent {
  @Input({ required: true }) documents!: IDocument;
  @Output() updateDocument: EventEmitter<{ key: keyof IDocument, value: string }> = new EventEmitter<{ key: keyof IDocument, value: string }>()

  documentIndex = 0;
  documentTypes: { label: string, value: keyof IDocument }[] = [];
  selectedDocumentType!: keyof IDocument;
  defaultPreview!: keyof IDocument;
  selectedDropdown!: { label: string, value: keyof IDocument };

  signature$ = this.actionsListener$.pipe(
    ofType(updateSignatureInDocumentDetails),
    tap(({ signatureBase64 }) => {
      this.documents['signature_base64'] = signatureBase64;
      this.defaultPreview = 'signature_base64'
    })
  )


  constructor(
    override store: Store,
    private actionsListener$: ActionsSubject
  ) {
    super(store)
  }

  ngOnInit() {
    this.translation$.pipe(
      take(1),
      tap(tr => {
        const keys = Object.keys(this.documents) as (keyof IDocument)[];
        if (tr) {
          this.fillDocumentTypes(tr, keys)
        }
      })
    ).subscribe()

    // Dispatch action with the default value when the component initializes
    const defaultBase64 = this.documents['signature_base64'] || ''; // Example of getting a default value
    if (defaultBase64) {
      this.store.dispatch(setImageBase64Data({ imageBase64: defaultBase64 }));
    }
  }

  deleteDocument(key: keyof IDocument) {
    this.documents[key] = ''
  }

  onFileSelected(event: FileSelectEvent): void {
    const files: File[] = event.files;
    if (files.length > 0) {
      this.convertFileToBase64(files[0]).subscribe({
        next: (base64String) => {
          const base64Data = base64String.split(',')[1];
          this.documents[this.selectedDropdown.value] = base64Data;
          this.defaultPreview = this.selectedDropdown.value;

          // Dispatch the action with the image base64 data
          this.store.dispatch(setImageBase64Data({ imageBase64: base64Data }));
        },
        error: (error) => {
          console.error('Error converting file to base64', error);
        }
      });
    }
  }

  convertFileToBase64(file: File): Observable<string> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };

      reader.onerror = (error) => {
        observer.error(error);
      };
    });
  }

  fillDocumentTypes(tr: { [key: string]: string }, keys: (keyof IDocument)[]) {

    keys.forEach(doc => {
      if (doc === 'signature_base64') {
        this.documentTypes.push({ label: tr?.['signature_base64_label'] ?? 'Signature', value: 'signature_base64' })
      }
      else if (doc === 'id_copy_front_base64') {
        this.documentTypes.push({ label: tr?.['id_copy_front_base64_label'] ?? 'Front ID', value: 'id_copy_front_base64' })
      }
      else if (doc === 'id_copy_back_base64') {
        this.documentTypes.push({ label: tr?.['id_copy_back_base64_label'] ?? 'Back ID', value: 'id_copy_back_base64' })
      }
      else if (doc === 'sad_document_base64') {
        this.documentTypes.push({ label: tr?.['sad_document_base64_label'] ?? 'SAD', value: 'sad_document_base64' })
      }
    })

    this.defaultPreview = this.documentTypes[0].value;
  }

  selectDocumentType(ev: DropdownChangeEvent) {
    console.log({ ev });
    this.defaultPreview = this.selectedDropdown.value;
  }
}
