import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LeftSideMenuComponent } from './left-side-menu/left-side-menu.component';
import { MatDrawer, MatDrawerMode, MatSidenavContainer, MatSidenavContent, MatSidenavModule } from '@angular/material/sidenav';
import { ButtonModule } from 'primeng/button';
import { HeaderComponent } from './header/header.component';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { delay, tap } from 'rxjs';
import { LetDirective } from '@ngrx/component';


@Component({
  selector: 'app-container',
  standalone: true,
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss'],
  imports: [
    CommonModule,
    LeftSideMenuComponent,
    MatSidenavModule,
    ButtonModule,
    HeaderComponent,
    RouterOutlet,
    LetDirective
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppContainerComponent {
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild('mat_content') matContent!: ElementRef;
  sideNavMode: MatDrawerMode = 'side';

  breakPointObserver$ = this.observer.observe(['(max-width: 991px)']).pipe(
    delay(1),
    tap((res) => {
      if (res.matches) {
        this.sideNavMode = 'over';
        this.drawer.mode = 'over';
        this.drawer.close();
      } else {
        this.sideNavMode = 'side';
        this.drawer.mode = 'side';
        this.drawer.open();
      }
    })
  );

  constructor(
    private observer: BreakpointObserver,
  ) { }

  toggleDrawer() {
    if (this.drawer) {
      this.drawer.toggle();
    }
  }
}
