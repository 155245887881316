import { Routes } from "@angular/router";
import { FulfilmentComponent } from "./fulfilment.component";
import { AuthGuard } from "../../core/auth/guards/auth.guard";
import { SearchComponent } from "./components/search/search.component";
import { PostpaidPage } from "./pages/postpaid/postpaid.page";
import { PrepaidPage } from "./pages/prepaid/prepaid.page";
import { PostpaidPortInPage } from "./pages/postpaid-portin/postpaid-portIn.page";
import { PrepaidPortInPage } from "./pages/prepaid-portIn/prepaid-portIn.page";

export const fulfilmentRoutes: Routes = [
    {
        path: 'fulfilment',
        component: FulfilmentComponent,
        children: [
            {
                path: '',
                redirectTo: 'search',
                pathMatch: 'full'
            },
            {
                path: 'search',
                component: SearchComponent
            },
            {
                path: 'postpaid/:id',
                component: PostpaidPage
            },
            {
                path: 'prepaid/:id',
                component: PrepaidPage
            },
            {
                path: 'postpaid-portIn/:id',
                component: PostpaidPortInPage
            },
            {
                path: 'prepaid-portIn/:id',
                component: PrepaidPortInPage
            }
        ],
    }
];
