<ng-container *ngrxLet="translation$ as tr">
  <div class="contact-details">
    <div class="contact-information">
      <div class="section-title body-medium-l">{{tr?.['contact_information_label']}}</div>
      <div class="items-from">
        <div class="other-form-items">
          @if (contactInfo.email.length) {
          <div class="form-item">
            <label for="">{{tr?.['email_label']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="contactInfo.email">
          </div>
          }
          @if (contactInfo.contact_number.length) {
          <div class="form-item">
            <label for="">{{tr?.['mobile_number_label']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="contactInfo.contact_number">
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</ng-container>