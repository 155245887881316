import { Component } from '@angular/core';
import { LoaderService } from './Loader.service';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports:[LetDirective]
})
export class LoaderComponent {

  loaderState$ = this.loaderService.loaderState

  constructor(private loaderService: LoaderService) { }
}
