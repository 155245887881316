import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment.prod';
import { setRefreshTokenAction } from '../../../store/auth/actions.auth';

@Injectable({
  providedIn: 'root'
})
export class ApiHeaderService {

  private readonly token = '';
  private readonly authHeaders = {
    'Content-Type': 'application/json',
    // cookie: `refresh_token=${this.token}`,
    'api-key': environment.apiKey
  };

  private readonly publicHeaders = {
    'content-type': 'application/json',
    'api-key': environment.apiKey,
  }

  constructor(private readonly store: Store) { }

  // setToken(refreshToken: string) {
  //   this.store.dispatch(setRefreshTokenAction({ refreshToken: refreshToken }));
  // }

  getAuthHeaders(): HttpHeaders {
    return new HttpHeaders(this.authHeaders).delete('content-type');
  }

  postAuthHeaders(): HttpHeaders {
    return new HttpHeaders(this.authHeaders);
  }

  getPublicHeaders() {
    return new HttpHeaders(this.publicHeaders).delete('content-type');
  }

  postPublicHeaders() {
    return new HttpHeaders(this.publicHeaders);
  }
}