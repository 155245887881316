<ng-container *ngrxLet="translation$ as tr">
  <div class="customer-details">
    <div class="customer-information">
      <div class="section-title body-medium-l">{{tr?.['customer_information_label']}}</div>
      <div class="items-from">
        <div class="form-item">
          <label for="">{{tr?.['customer_details_title_picker']}}</label>
          <input type="text" pInputText [disabled]="true" [value]="information.title">
        </div>
        <div class="other-form-items">
          <div class="form-item">
            <label for="">{{tr?.['customer_details_first_name_text_field']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="information.first_name">
          </div>
          <div class="form-item">
            <label for="">{{tr?.['second_name_label']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="information.second_name">
          </div>
          <div class="form-item">
            <label for="">{{tr?.['third_name_label']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="information.middle_name">
          </div>
          <div class="form-item">
            <label for="">{{tr?.['customer_details_last_name_text_field']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="information.last_name">
          </div>
          <div class="form-item">
            <label for="">{{tr?.['id_type_text_field']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="information.id_type">
          </div>
          <div class="form-item">
            <label for="">{{tr?.['customer_details_id_number_text_field']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="information.id_number">
          </div>
          <div class="form-item">
            <label for="">{{ tr?.['id_expiry_date_label'] }}</label>
            <input type="text" pInputText [disabled]="true" [value]="formatDate(information.id_expiry_date)" />
          </div>
          <div class="form-item">
            <label for="">{{ tr?.['customer_details_date_of_birth_picker'] }}</label>
            <input type="text" pInputText [disabled]="true" [value]="formatDate(information.date_of_birth)" />
          </div>
          <div class="form-item">
            <label for="">{{tr?.['nationality_text_field']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="information.nationality">
          </div>
          <div class="form-item">
            <label for="">{{tr?.['customer_details_gender_picker']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="information.gender">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>