import { versionDate } from './versionDate';

export const environment = {
  production: false,
  baseApiUrl: `https://oodev.salespoint.live`,
  angularLink: 'https://oowebdev.salespoint.live',
  domain: '',
  apiKey: 'c2FsZXNwb2ludF9kZmdramc=',
  versionDate
};
