import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { ButtonModule } from 'primeng/button';
import { BaseComponent } from '../base.component';
import { Store } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-popup',
  standalone: true,
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
  imports: [CommonModule, ButtonModule, LetDirective],
  encapsulation: ViewEncapsulation.None
})
export class AlertPopupComponent extends BaseComponent {
  @Input() statusCode: number | null = null;
  @Input() responseMessage: string = '';

  constructor(
    override store: Store,
    public activeModal: NgbActiveModal
  ) {
    super(store);
  }

}
