import { Routes } from '@angular/router';
import { FulfilmentComponent } from '../../features/fulfilment/fulfilment.component';
import { AppContainerComponent } from './app-container.component';
import { PortInComponent } from '../../features/portIn/portIn.component';
import { DigitalB2BComponent } from '../../features/digitalB2B/digitalB2B.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { fulfilmentRoutes } from '../../features/fulfilment/fulfilment.routes';

export const appContainerRoutes: Routes = [
    {
        path: 'home',
        component: AppContainerComponent,
        canActivate: [AuthGuard],
        children: [
            ...fulfilmentRoutes,
            {
                path: '',
                redirectTo: 'landing-page',
                pathMatch: 'full'
            },
            {
                path: 'landing-page',
                component: LandingPageComponent
            },
            {
                path: 'digital-b2b',
                component: DigitalB2BComponent
            },
            {
                path: 'port-in',
                component: PortInComponent
            }
        ],
    }

];
