import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiHeaderService } from "./api-headers.service";
import { ISystemLookupResponse } from "../interfaces/systemLookups.interface";
import { ISystemSettingsResponse } from "../interfaces/systemSettings.interface";
import { ITranslationResponse } from "../interfaces/translation.interface";

@Injectable({
    providedIn: 'root'
})
export class SharedApiService {
    baseUrl: string = '';

    constructor(private readonly http: HttpClient, private readonly apiHeader: ApiHeaderService) {
        this.baseUrl = environment.baseApiUrl;
    }

    getSystemLookups(): Observable<ISystemLookupResponse> {
        const _baseUrl = this.baseUrl + '/api/Core/SalesPoint/v1.0/GetSystemLookups';
        const headers = this.apiHeader.getPublicHeaders();
        return this.http.get<ISystemLookupResponse>(_baseUrl, { headers });
    }

    getSystemSettings(): Observable<ISystemSettingsResponse> {
        const _baseUrl = this.baseUrl + '/api/Core/SalesPoint/v1.0/GetSystemSettings';
        const headers = this.apiHeader.getPublicHeaders();
        return this.http.get<ISystemSettingsResponse>(_baseUrl, { headers });
    }

    translation(): Observable<ITranslationResponse> {
        const _baseUrl = this.baseUrl + '/api/Core/SalesPoint/v1.0/translation';
        const headers = this.apiHeader.getPublicHeaders();
        return this.http.get<ITranslationResponse>(_baseUrl, { headers });
    }
}