import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private readonly router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const refreshToken = localStorage.getItem('refresh_token');

    if (refreshToken?.length) {
      return true;
    }

    this.router.navigate(['login']);
    return false;
  }
}