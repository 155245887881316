import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { FulfillmentEffects } from '../../../store/fulfilment/effects.fulfilment';

@Component({
  selector: 'app-fulfilment',
  standalone: true,
  templateUrl: './fulfilment.component.html',
  styleUrls: ['./fulfilment.component.scss'],
  imports: [
    CommonModule,
    RouterOutlet,
  ]
})
export class FulfilmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}