import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as fulfillmentActions from './actions.fulfilment';
import { FulfillmentApiService } from '../../app/features/fulfilment/services/fulfilment-api.service';

@Injectable()
export class FulfillmentEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private fulfillmentApiService: FulfillmentApiService,
  ) { }

  getPostpaidDetails$ = createEffect(() => this.actions$.pipe(
    ofType(fulfillmentActions.postpaidDetailsActions.getPostpaidDetails),
    switchMap(({ id }) => this.fulfillmentApiService.getPostpaidDetails(id)),
    map(response => fulfillmentActions.postpaidDetailsActions.getPostpaidDetailsSuccess({ response })),
    catchError((error: any) => of(fulfillmentActions.postpaidDetailsActions.getPostpaidDetailsFailure({ error })))
  ));

  getPrepaidDetails$ = createEffect(() => this.actions$.pipe(
    ofType(fulfillmentActions.prepaidDetailsActions.getPrepaidDetails),
    switchMap(({ id }) => this.fulfillmentApiService.getPrepaidDetails(id)),
    map(response => fulfillmentActions.prepaidDetailsActions.getPrepaidDetailsSuccess({ response })),
    catchError((error: any) => of(fulfillmentActions.prepaidDetailsActions.getPrepaidDetailsFailure({ error })))

  ));

  fulfillMobilePrepaid$ = createEffect(() => this.actions$.pipe(
    ofType(fulfillmentActions.fulfillMobilePrepaidActions.fulfillMobilePrepaid),
    switchMap(({ request }) => this.fulfillmentApiService.fulfillMobilePrepaid(request)),
    map(response => fulfillmentActions.fulfillMobilePrepaidActions.fulfillMobilePrepaidSuccess({ response })),
    catchError((error: any) => of(fulfillmentActions.fulfillMobilePrepaidActions.fulfillMobilePrepaidFailure({ error })))
  ));

  fulfillMobilePostpaid$ = createEffect(() => this.actions$.pipe(
    ofType(fulfillmentActions.fulfillMobilePostpaidActions.fulfillMobilePostpaid),
    switchMap(({ request }) => this.fulfillmentApiService.fulfillMobilePostpaid(request)),
    map(response => fulfillmentActions.fulfillMobilePostpaidActions.fulfillMobilePostpaidSuccess({ response })),
    catchError((error: any) => of(fulfillmentActions.fulfillMobilePostpaidActions.fulfillMobilePostpaidFailure({ error })))
  ));

  getSimReplacementDetails$ = createEffect(() => this.actions$.pipe(
    ofType(fulfillmentActions.simReplacementDetailsActions.getSimReplacementDetails),
    switchMap(({ id }) =>
      this.fulfillmentApiService.getSimReplacementDetails(id)),
    map(response => fulfillmentActions.simReplacementDetailsActions.getSimReplacementDetailsSuccess({ response })),
    catchError((error: any) => of(fulfillmentActions.simReplacementDetailsActions.getSimReplacementDetailsFailure({ error })))

  ));

  getPostpaidPortInDetails$ = createEffect(() => this.actions$.pipe(
    ofType(fulfillmentActions.postpaidPortInActions.getPostpaidPortInDetails),
    switchMap(({ id }) =>
      this.fulfillmentApiService.getPostpaidPortInDetails(id)),
    map(response => fulfillmentActions.postpaidPortInActions.getPostpaidPortInDetailsSuccess({ response })),
    catchError((error: any) => of(fulfillmentActions.postpaidPortInActions.getPostpaidPortInDetailsFailure({ error })))
  ));

  getPrepaidPortInDetails$ = createEffect(() => this.actions$.pipe(
    ofType(fulfillmentActions.prepaidPortInActions.getPrepaidPortInDetails),
    switchMap(({ id }) =>
      this.fulfillmentApiService.getPrepaidPortInDetails(id)),
    map(response => fulfillmentActions.prepaidPortInActions.getPrepaidPortInDetailsSuccess({ response })),
    catchError((error: any) => of(fulfillmentActions.prepaidPortInActions.getPrepaidPortInDetailsFailure({ error })))
  ));
}
