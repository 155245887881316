<ng-container *ngrxLet="translation$ as tr">
  <div class="contract-model-container">
    <div class="title-medium-m">{{tr?.['contract_label']}}</div>
    <div class="content-contract">
      <div *ngIf="responseData?.contract">
        <pdf-viewer [src]="'data:application/pdf;base64,' + responseData.contract" [render-text]="true"
          style="display: block; height: 500px;">
        </pdf-viewer>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <p-button class="p-button-md" (click)="activeModal.close('Close click')"
          (keydown)="onClose($event)">Done</p-button>
      </div>
    </div>
  </div>
</ng-container>