import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IFulfilmentRequests } from '../../interfaces/search.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-SearchModal',
  standalone: true,
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
  imports: [CommonModule, ButtonModule, RadioButtonModule, FormsModule, ReactiveFormsModule],
  encapsulation: ViewEncapsulation.None
})
export class SearchModalComponent {
  @Input() fulfillmentRequests: IFulfilmentRequests[] = [];
  selectedMsisdn!: string;
  selectedProcessTypeCode!: string;

  constructor(public activeModal: NgbActiveModal, private readonly router: Router) { }

  selectRequest(): void {
    debugger
    const selectedRequest = this.fulfillmentRequests.find(request => request.msisdn === this.selectedMsisdn);
    if (selectedRequest) {
      this.selectedProcessTypeCode = selectedRequest.process_type_code;

      const queryParams = { processTypeCode: this.selectedProcessTypeCode };

      switch (selectedRequest.process_type_code) {
        case 'mobile_postpaid':
          this.router.navigate(['/home/fulfilment/postpaid', selectedRequest.id], { queryParams });
          break;
        case 'mobile_prepaid':
          this.router.navigate(['/home/fulfilment/prepaid', selectedRequest.id], { queryParams });
          break;
        case 'postpaid_portin_activation':
          this.router.navigate(['/home/fulfilment/postpaid-portIn', selectedRequest.id], { queryParams });
          break;
        case 'mobile_prepaid_portin_activation':
          this.router.navigate(['/home/fulfilment/prepaid-portIn', selectedRequest.id], { queryParams });
          break;
        default:
          break;
      }
      this.activeModal.close('Select click');
    }
  }

  onClose(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.activeModal.close('Close click');
      event.preventDefault();
    }
  }
}
