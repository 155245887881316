import { createAction, createActionGroup, props } from '@ngrx/store';
import { ILoginRequest, ILoginResponse, IRefreshToken } from '../../app/core/auth/interfaces/auth.interface';
import { IUpdateLocalStatePayload } from '../interfaces/updateLocalState.interface';
import { AuthState } from './reducer.auth';

export const updateLocalAuthState = createAction(
  '[AUTH] update local auth state',
  props<IUpdateLocalStatePayload<AuthState>>()
)

export const basicLoginActions = createActionGroup({
  source: 'AUTH',
  events: {
    'try login': props<{ body: ILoginRequest }>(),
    'login success': props<{ loginResponse: ILoginResponse }>(),
    'login failure': props<{ error: Error }>(),
  }
});

export const setRefreshTokenAction = createAction(
  '[AUTH] set refresh token',
  props<{ refreshToken: string }>()
)

export const refreshTokenActions = createActionGroup({
  source: 'AUTH',
  events: {
    'try refresh token': props<{ refreshToken: IRefreshToken }>(),
    'refresh token success': props<{ loginResponse: ILoginResponse }>(),
    'refresh token failure': props<{ error: Error }>(),
  }
});