import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPlanInfo } from '../../interfaces/plan-info.interface';
import { BaseComponent } from '../../../../shared/components/base.component';
import { LetDirective } from '@ngrx/component';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'app-plan-info',
  standalone: true,
  templateUrl: './plan-info.component.html',
  styleUrls: ['./plan-info.component.scss'],
  imports: [
    InputTextModule,
    LetDirective,
    BadgeModule,
    CommonModule, ButtonModule, RadioButtonModule, FormsModule, ReactiveFormsModule
  ]
})
export class PlanInfoComponent extends BaseComponent {
  @Input() planInfo!: IPlanInfo;

  constructor(override store: Store) {
    super(store)
  }

}
