<ng-container *ngrxLet="translation$ as tr">
  <ng-container *ngrxLet="lookups$ as lookups">
    <div class="address-details-details">
      <div class="address-details-information">
        <div class="section-title body-medium-l">Address Details</div>
        <div class="items-from">
          <div class="subtitle">Billing address</div>
          <div class="other-form-items">
            <div class="form-item">
              <label for="">{{tr?.['city_label']}}</label>
              <p-dropdown class="w-100" [options]="cities" optionLabel="name" placeholder="City"
                [(ngModel)]="selectedCity" value="k" (onChange)="onCityChange($event)">
              </p-dropdown>
            </div>
            <div class="form-item">
              <label for="">{{tr?.['pobox_label']}}</label>
              <input type="text" pInputText [value]="addressInfo.po_box" (input)="onInputChange($event, 'po_box')">
            </div>
          </div>
          <div class="form-item">
            <label for="">{{tr?.['pc_code_label']}}</label>
            <input type="text" pInputText [value]="addressInfo.pc_code_id"
              (input)="onInputChange($event, 'pc_code_id')">
          </div>
        </div>
        <div class="items-from">
          <div class="subtitle">Physical address</div>
          <div class="other-form-items">
            <div class="form-item">
              <label for="">{{ tr?.['region_label'] }}</label>
              <p-dropdown class="w-100" [options]="regions" [(ngModel)]="selectedRegion"
                (onChange)="onRegionChange($event)" optionLabel="name" placeholder="Region">
              </p-dropdown>
            </div>

            <div class="form-item">
              <label for="">{{ tr?.['walleye_label'] }}</label>
              <p-dropdown class="w-100" [options]="wilayas" [(ngModel)]="selectedWilaya" [disabled]="!selectedRegion"
                (onChange)="onWilayaChange($event)" optionLabel="name" placeholder="Wilaya">
              </p-dropdown>
            </div>

            <div class="form-item">
              <label for="">{{ tr?.['locality_label'] }}</label>
              <p-dropdown class="w-100" [options]="localities" [(ngModel)]="selectedLocality"
                [disabled]="!selectedWilaya" optionLabel="name" placeholder="Locality"
                (onChange)="onLocalityChange($event)">
              </p-dropdown>
            </div>

            <div class="form-item">
              <label for="">{{tr?.['way_number_label']}}</label>
              <input type="text" pInputText [value]="addressInfo.way_number"
                (input)="onInputChange($event, 'way_number')">
            </div>
            <div class="form-item">
              <label for="">{{tr?.['house_number_label']}}</label>
              <input type="text" pInputText [value]="addressInfo.house_number"
                (input)="onInputChange($event, 'house_number')">
            </div>
            <div class="form-item">
              <label for="">{{tr?.['land_mark_label']}}</label>
              <input type="text" pInputText [value]="addressInfo.landmark" (input)="onInputChange($event, 'landmark')">
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>