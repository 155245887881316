import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Store } from '@ngrx/store';
import { LetDirective } from '@ngrx/component';
import { LookupArray } from '../../../../shared/interfaces/systemLookups.interface';
import { LookupsExtractPipe } from '../../../../shared/pipes/lookups-extract.pipe';
import { FulfillmentApiService } from '../../services/fulfilment-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IFulfilmentRequests } from '../../interfaces/search.interface';
import { SearchModalComponent } from '../searchModal/search-modal.component';
@Component({
  selector: 'app-search',
  standalone: true,
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  imports: [
    CommonModule,
    TabViewModule,
    ButtonModule,
    DropdownModule,
    ReactiveFormsModule,
    InputTextModule,
    LetDirective,
    LookupsExtractPipe
  ],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent extends BaseComponent {
  filteredLookups: LookupArray[] = [];
  searchForm: FormGroup;
  activeTab: string = 'ID Type';

  constructor(
    override store: Store,
    private fb: FormBuilder,
    private fulfillmentApiService: FulfillmentApiService,
    private modalService: NgbModal,  // Inject NgbModal service
  ) {
    super(store);
    this.searchForm = this.fb.group({
      msisdn: [''],
      id_type: [''],
      id_number: ['']
    });
  }

  ngOnInit(): void {
    this.setDynamicValidation();
  }

  setDynamicValidation(): void {
    if (this.activeTab === 'ID Type') {
      this.searchForm.get('id_type')?.setValidators(Validators.required);
      this.searchForm.get('id_number')?.setValidators(Validators.required);
      this.searchForm.get('msisdn')?.clearValidators();
    } else if (this.activeTab === 'MSISDN') {
      this.searchForm.get('id_type')?.clearValidators();
      this.searchForm.get('id_number')?.clearValidators();
      this.searchForm.get('msisdn')?.setValidators(Validators.required);
    }
    this.searchForm.get('id_type')?.updateValueAndValidity();
    this.searchForm.get('id_number')?.updateValueAndValidity();
    this.searchForm.get('msisdn')?.updateValueAndValidity();
  }

  onTabChange(event: any): void {
    this.searchForm.reset(); // Reset the form values

    this.activeTab = event.index === 0 ? 'ID Type' : 'MSISDN';
    this.setDynamicValidation();
  }

  onSubmit(): void {
    if (this.searchForm.valid) {
      const formValue = { ...this.searchForm.value };

      // Convert null or undefined to empty strings for all fields
      Object.keys(formValue).forEach((key) => {
        if (formValue[key] == null) {
          formValue[key] = '';
        }
      });

      // Convert id_type to its name property if it exists
      if (formValue.id_type && typeof formValue.id_type === 'object' && formValue.id_type.name) {
        formValue.id_type = formValue.id_type.name;
      }

      // Call the API
      this.fulfillmentApiService.searchFulfillment(formValue).subscribe({
        next: (response) => {
          this.openModal(response.requests, false);
        },
        error: (err) => {
          this.openModal([], true, err);
        }
      });
    } else {
      this.searchForm.markAllAsTouched(); // Mark all as touched for validation messages
    }
  }


  openModal(fulfillmentRequests: IFulfilmentRequests[], isError: boolean, errorMessage?: string): void {
    const modalRef = this.modalService.open(SearchModalComponent, { centered: true });
    modalRef.componentInstance.fulfillmentRequests = fulfillmentRequests;
    modalRef.componentInstance.isError = isError;
    modalRef.componentInstance.errorMessage = errorMessage;
  }

}
