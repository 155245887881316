import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {

  constructor(
    private readonly router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const refreshToken = localStorage.getItem('refresh_token');

    if (refreshToken?.length) {
      this.router.navigate(['home']);
      return false;
    }

    return true
  }
}
