import { CommonModule } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs';
import { postpaidDetailsActions } from '../../../../../store/fulfilment/actions.fulfilment';
import { selectPostpaidDetails } from '../../../../../store/fulfilment/selectors.fulfilment';
import { LetDirective } from '@ngrx/component';
import { IOrganizePostpaidDetailsResponse, IPostpaidDetailsResponse } from '../../interfaces/postpaid-details.interface';
import { CustomerInfoComponent } from '../../components/customer-info/customer-info.component';
import { SimInfoComponent } from '../../components/sim-info/sim-info.component';
import { PlanInfoComponent } from '../../components/plan-info/plan-info.component';
import { IPlanInfo, IPlanSpecification } from '../../interfaces/plan-info.interface';
import { AddressDetailsComponent } from '../../components/address-details/address-details.component';
import { AddContactComponent } from '../../components/add-contact/add-contact.component';
import { SignaturePadComponent } from '../../components/signature-pad/signature-pad.component';
import { TermsAndConditionsComponent } from '../../components/terms-and-conditions/terms-and-conditions.component';
import { DetailsDocumentComponent } from "../../components/details-document/details-document.component";
import { FooterFulfilmentComponent } from "../../footer-fulfilment/footer-fulfilment.component";
import { ContractComponent } from "../../components/contract/contract.component";
import { IFulfillMobilePostpaidRequest } from '../../interfaces/fulfill-mobile-postpaid.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertPopupComponent } from '../../../../shared/components/alert-popup/alert-popup.component';
import { FulfillmentApiService } from '../../services/fulfilment-api.service';
import { ContractModalComponent } from '../../components/contract-modal/contract-modal.component';

@Component({
  selector: 'app-postpaid',
  templateUrl: './postpaid.page.html',
  styleUrls: ['./postpaid.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    CustomerInfoComponent,
    SimInfoComponent,
    PlanInfoComponent,
    AddressDetailsComponent,
    AddContactComponent,
    SignaturePadComponent,
    TermsAndConditionsComponent,
    DetailsDocumentComponent,
    FooterFulfilmentComponent,
    ContractComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class PostpaidPage {
  @ViewChild(TermsAndConditionsComponent) termsAndConditionsComponent!: TermsAndConditionsComponent;

  iccid: string = '';
  searchType: string = '';
  idPostpaid: number = 0;
  isContractOpened: boolean = false;

  routerParams$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    map(id => id ? +id : null),
    tap(id => {
      if (id !== null) {
        this.idPostpaid = id;
        this.store.dispatch(postpaidDetailsActions.getPostpaidDetails({ id }));
      }
    })
  );

  selectorPostpaidDetailsData$ = this.store.select(selectPostpaidDetails).pipe(
    filter(res => res !== null),
    map((res) => this.organizeDetails(res as IPostpaidDetailsResponse))

  )

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private fulfillmentApiService: FulfillmentApiService,
    private modalService: NgbModal
  ) {
    this.route.queryParams.subscribe(params => {
      this.searchType = params['processTypeCode'];
    });
  }

  private buildRequestData(data: IOrganizePostpaidDetailsResponse, isShowContract: boolean): IFulfillMobilePostpaidRequest {
    return {
      documents: data.documents!,
      billing_address: {
        city_id: data.address_details.city_id,
        pc_code: data.address_details.pc_code_id,
        pobox: data.address_details.po_box
      },
      contact_info: {
        contact_number: data.contact_info.contact_number,
        email: data.contact_info.email,
        is_billing_email: data.contact_info.is_billing_email,
        search_type: data.contact_info.search_type
      },
      is_show_contract: isShowContract,
      physical_address: {
        region_id: data.address_details.region_id,
        wilaya_id: data.address_details.wilaya_id,
        locality_id: data.address_details.locality_id,
        way_number: data.address_details.way_number,
        house_flat_number: data.address_details.house_number,
        land_mark: data.address_details.landmark
      },
      id_expiry_date: data.customer_info.id_expiry_date,
      iccid: this.iccid,
      id: this.idPostpaid.toString()
    };
  }

  private submitRequest(requestData: IFulfillMobilePostpaidRequest) {
    this.fulfillmentApiService.fulfillMobilePostpaid(requestData).subscribe({
      next: (response) => {
        if (requestData.is_show_contract) {
          const modalRef = this.modalService.open(ContractModalComponent, { centered: true, size: 'xl', scrollable: false });
          modalRef.componentInstance.responseData = response;
        } else {
          const modalRef = this.modalService.open(AlertPopupComponent, { centered: true });
          modalRef.componentInstance.statusCode = 200;
          modalRef.componentInstance.responseMessage = response.message;
        }
      },
      error: (error) => {
        const modalRef = this.modalService.open(AlertPopupComponent, { centered: true });
        modalRef.componentInstance.statusCode = 400;
        modalRef.componentInstance.responseMessage = error.error.message;
      }
    });
  }

  // Method to handle the contract modal
  openContractModal(data: IOrganizePostpaidDetailsResponse) {
    this.termsAndConditionsComponent.validateTerms();

    if (this.termsAndConditionsComponent.checked) {
      const requestData = this.buildRequestData(data, true);

      this.fulfillmentApiService.fulfillMobilePostpaid(requestData).subscribe({
        next: (response) => {
          const modalRef = this.modalService.open(ContractModalComponent, { centered: true, size: 'xl', scrollable: false });
          modalRef.componentInstance.responseData = response;
          this.isContractOpened = true;
        },
        error: (error) => {
          const modalRef = this.modalService.open(AlertPopupComponent, { centered: true });
          modalRef.componentInstance.statusCode = 400;
          modalRef.componentInstance.responseMessage = error.error.message;
        }
      });
    }
  }

  // Method to handle normal submit
  onSubmit(data: IOrganizePostpaidDetailsResponse) {
    this.termsAndConditionsComponent.validateTerms();
    debugger
    if (this.termsAndConditionsComponent.checked) {
      if (!this.isContractOpened) {
        const modalRef = this.modalService.open(AlertPopupComponent, { centered: true });
        modalRef.componentInstance.statusCode = 400;
        modalRef.componentInstance.responseMessage = "Please show contract";
      } else {
        const requestData = this.buildRequestData(data, false);

        this.fulfillmentApiService.fulfillMobilePostpaid(requestData).subscribe({
          next: (response) => {
            const modalRef = this.modalService.open(AlertPopupComponent, { centered: true });
            modalRef.componentInstance.statusCode = 200;
            modalRef.componentInstance.responseMessage = response.message;
          },
          error: (error) => {
            const modalRef = this.modalService.open(AlertPopupComponent, { centered: true });
            modalRef.componentInstance.statusCode = 400;
            modalRef.componentInstance.responseMessage = error.error.message;
          }
        });
      }

    }
  }

  handleContractAction(showContract: boolean, data: IOrganizePostpaidDetailsResponse) {
    if (showContract) {
      this.openContractModal(data);
    } else {
      this.onSubmit(data);
    }
  }

  handleIccidChange(newIccid: string): void {
    this.iccid = newIccid;
  }

  defaultString(value: string | undefined): string {
    return value ?? '';
  }

  defaultNumber(value: string | undefined): string {
    return (value === undefined || value === null || value.trim() === '') ? '0.0' : value;
  }

  organizeDetails(res: IPostpaidDetailsResponse): IOrganizePostpaidDetailsResponse {
    return {
      customer_info: {
        title: this.defaultString(res?.title),
        first_name: this.defaultString(res?.first_name),
        second_name: this.defaultString(res?.second_name),
        middle_name: this.defaultString(res?.middle_name),
        last_name: this.defaultString(res?.last_name),
        id_type: this.defaultString(res?.id_type),
        id_number: this.defaultString(res?.id_number),
        id_expiry_date: this.defaultString(res?.id_expiry_date),
        date_of_birth: this.defaultString(res?.date_of_birth),
        nationality: this.defaultString(res?.nationality),
        gender: this.defaultString(res?.gender),
      },
      contact_info: {
        contact_number: res?.contact_number || '',
        email: res?.email || '',
        is_billing_email: res?.is_billing_email
      },
      sim_info: {
        msisdn: this.defaultString(res?.msisdn),
        vanity_level: this.defaultString(res?.vanity_level),
        vanity_amount: this.defaultString(res?.vanity_amount),
      },
      plan_info: this.organizePlanDetails(res),
      documents: JSON.parse(JSON.stringify(res.documents)),
      address_details: {
        city_id: this.defaultString(res?.city_id),
        city_description: this.defaultString(res?.city_description),
        po_box: this.defaultString(res?.po_box),
        pc_code_id: this.defaultString(res?.pc_code_id),
        region_id: this.defaultString(res?.region_id),
        region_description: this.defaultString(res?.region_description),
        wilaya_id: this.defaultString(res?.wilaya_id),
        wilaya_description: this.defaultString(res?.wilaya_description),
        locality_id: this.defaultString(res?.locality_id),
        locality_description: this.defaultString(res?.locality_description),
        way_number: this.defaultString(res?.way_number),
        house_number: this.defaultString(res?.house_number),
        landmark: this.defaultString(res?.landmark),
      }
    };
  }

  organizePlanDetails(res: IPostpaidDetailsResponse): IPlanInfo {
    return {
      is_paid: res.is_paid,
      salary_range_description: this.defaultString(res?.salary_range_description),
      salary_range_id: this.defaultNumber(res?.salary_range_id),
      installment_amount: this.defaultNumber(res.plan_details?.installment_amount),
      vanity_amount: this.defaultNumber(res.plan_details?.vanity_amount),
      deposit_amount: this.defaultNumber(res.plan_details?.deposit_amount),
      total_due_amount: this.defaultNumber(res.plan_details?.total_due_amount),
      validate: this.defaultString(res.plan_details?.validate),
      flexi_mins: this.defaultString(res.plan_details?.flexi_mins),
      cross_mins: this.defaultString(res.plan_details?.cross_mins),
      valid_for: this.defaultString(res.plan_details?.valid_for),
      price: {
        amount: this.defaultString(res.plan_details?.price?.amount),
        amount_inclusive_vat: this.defaultString(res.plan_details?.price?.amount_inclusive_vat),
        vat_amount: this.defaultString(res.plan_details?.price?.vat_amount),
        vat_percentage: this.defaultString(res.plan_details?.price?.vat_percentage),
        vat_type: this.defaultString(res.plan_details?.price?.vat_type),
      },
      name: this.defaultString(res.plan_details?.name),
      data: this.defaultString(res.plan_details?.data),
      time: this.defaultString(res.plan_details?.time),
      plan_specifications: (res.plan_details?.plan_specifications || []).map((spec: IPlanSpecification) => ({
        quantity_unit: this.defaultString(spec.quantity_unit),
        name: this.defaultString(spec.name),
        value: this.defaultString(spec.value),
        class_name: this.defaultString(spec.class_name),
      }))
    };
  }
}
