import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectSystemLookups, selectTranslation } from '../../../store/base/selectors.base';

@Directive()
export abstract class BaseComponent {

  translation$ = this.store.select(selectTranslation).pipe(
    filter(tr => tr !== null),
  );

  lookups$ = this.store.select(selectSystemLookups).pipe(
    filter(tr => tr !== null),
  );

  constructor(protected store: Store) {}

}
