import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthApiService } from '../../app/core/auth/services/auth-api.service';
import * as authActions from './actions.auth';
import { AuthService } from '../../app/core/auth/services/auth.service';
import { from } from 'rxjs';
import { EncryptionService } from '../../app/core/auth/services/encryption.service';
import { IRefreshToken } from '../../app/core/auth/interfaces/auth.interface';

@Injectable()
export class AuthEffect {

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly authApi: AuthApiService,
    private readonly auth: AuthService,
    private readonly encryptionService: EncryptionService
  ) { }

  login$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.basicLoginActions.tryLogin),
    switchMap(({ body }) => this.authApi.onLogin(body).pipe(
      switchMap((res) => this.encryptionService.encryptToken(res.data.refresh_token).pipe(
        tap(({ encryptedToken }) => {
          localStorage.setItem('refresh_token', encryptedToken);
          this.auth.successLogin(encryptedToken, res.data.refresh_token);
        }),
        mergeMap(() => from([
          authActions.basicLoginActions.loginSuccess({ loginResponse: res }),
          authActions.setRefreshTokenAction({ refreshToken: res.data.token })
        ]))
      )),
      catchError((error: any, caught) => {
        this.store.dispatch(authActions.basicLoginActions.loginFailure({ error }));
        return caught;
      })
    ))
  ));

  refreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.refreshTokenActions.tryRefreshToken),
    switchMap(({ refreshToken }: { refreshToken: IRefreshToken }) =>
      this.authApi.refreshToken(refreshToken).pipe(
        switchMap((res) =>
          this.encryptionService.encryptToken(res.data.refresh_token).pipe(
            tap(({ encryptedToken }) => {
              localStorage.setItem('refresh_token', encryptedToken);
              console.log(res)
            }),
            mergeMap(() => from([
              authActions.basicLoginActions.loginSuccess({ loginResponse: res }),
              authActions.setRefreshTokenAction({ refreshToken: res.data.token })
            ]))
          )
        ),
        catchError((error: any, caught) => {
          this.store.dispatch(authActions.refreshTokenActions.refreshTokenFailure({ error }));
          return caught;
        })
      )
    )
  ));
}