<ng-container *ngrxLet="translation$ as tr">
  <div class="contact-details">
    <div class="contact-information">
      <div class="section-title body-medium-l">{{tr?.['add_contact_label']}}</div>
      <div class="items-from">
        <div class="other-form-items">
          <div class="form-item">
            <label for="">{{tr?.['phone_label']}}</label>
            <input type="text" pInputText [value]="contactInfo.contact_number">
          </div>
          <div class="form-item">
            <label for="">{{tr?.['email_label']}}</label>
            <input type="text" pInputText [value]="contactInfo.email">
          </div>
          <div class="is-billing-email">
            <p-checkbox [(ngModel)]="contactInfo.is_billing_email" [binary]="true" inputId="IsBillingEmail"
              [disabled]="true" />
            <label class="w-100">{{tr?.['is_billing_email_label']}}</label>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-container>