import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { ActionsSubject, Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { setImageBase64Data, updateSignatureInDocumentDetails } from '../../../../../store/fulfilment/actions.fulfilment';
import { BaseComponent } from '../../../../shared/components/base.component';
import { ofType } from '@ngrx/effects';
import { tap } from 'rxjs';


@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
  standalone: true,
  imports: [
    ButtonModule,
    LetDirective
  ]
})
export class SignaturePadComponent extends BaseComponent {
  @ViewChild('signatureCanvas', { static: true }) signatureCanvas!: ElementRef<HTMLCanvasElement>;
  @Output() signed: EventEmitter<string> = new EventEmitter<string>()

  signatureImg: string = ''
  documentType!: string;

  constructor(
    private renderer: Renderer2,
    override store: Store,
    private actionsListener$: ActionsSubject
  ) {
    super(store);
    // Listen for the action to get the document type
    this.actionsListener$.pipe(
      ofType(setImageBase64Data),
      tap(({ imageBase64 }) => {
        this.signatureImg = imageBase64;
      })
    ).subscribe();
  }

  ngAfterViewInit() {
    this.renderer.listen('document', 'SigCaptureWeb_SignResponse', (event) => this.signResponse(event));
  }

  Signature() {
    const canvas = this.signatureCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    ctx?.clearRect(0, 0, canvas.width, canvas.height);

    const imgWidth = 500;
    const imgHeight = 140;
    const message = {
      "firstName": "",
      "lastName": "",
      "eMail": "",
      "location": "",
      "imageFormat": 1,
      "imageX": imgWidth,
      "imageY": imgHeight,
      "imageTransparency": false,
      "imageScaling": false,
      "maxUpScalePercent": 0.0,
      "rawDataFormat": "ENC",
      "minSigPoints": 25
    };

    const messageData = JSON.stringify(message);

    const element = this.renderer.createElement('SigCaptureWeb_ExtnDataElem');
    this.renderer.setAttribute(element, 'SigCaptureWeb_MsgAttribute', messageData);
    this.renderer.appendChild(document.documentElement, element);

    const evt = new Event('SigCaptureWeb_SignStartEvent', { bubbles: true, cancelable: false });
    element.dispatchEvent(evt);
  }

  signResponse(event: any) {
    const str = event.target.getAttribute('SigCaptureWeb_msgAttri');
    const obj = JSON.parse(str);
    if (!obj.errorMsg) {
      this.signatureImg = obj.imageData;
      this.onSigned(obj.imageData);
    } else {
      alert(obj.errorMsg)
    }
  }

  onSigned(signature: string) {
    this.store.dispatch(updateSignatureInDocumentDetails({ signatureBase64: signature }))
    this.signed.emit(signature)
  }
}


