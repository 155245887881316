import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiHeaderService } from '../../../shared/services/api-headers.service';
import { ISearchRequest, ISearchResponse } from '../interfaces/search.interface';
import { IPostpaidDetailsResponse } from '../interfaces/postpaid-details.interface';
import { IPrepaidDetailsResponse } from '../interfaces/prepaid-details.interface';
import { ISimReplacementDetailsResponse } from '../interfaces/sim-replacement-details.interface';
import { IPostpaidPortInResponse } from '../interfaces/postpaid-portIn-details.interface';
import { IPrepaidPortInResponse } from '../interfaces/prepaid-portIn-details.interface';
import { IFulfillMobilePrepaidRequest, IFulfillMobilePrepaidResponse } from '../interfaces/fulfill-mobile-prepaid.interface';
import { IFulfillMobilePostpaidRequest, IFulfillMobilePostpaidResponse } from '../interfaces/fulfill-mobile-postpaid.interface';

@Injectable({
    providedIn: 'root'
})
export class FulfillmentApiService {
    baseUrl: string = ''

    constructor(private readonly http: HttpClient, private readonly apiHeader: ApiHeaderService) {
        this.baseUrl = environment.baseApiUrl
    }

    searchFulfillment(body: ISearchRequest): Observable<ISearchResponse> {
        const _baseUrl = this.baseUrl + '/api/Ooredoo/SalesPoint/v2.0/SearchFulfillmentRequest';
        const headers = this.apiHeader.postAuthHeaders();
        const bodyString = JSON.stringify(body)
        return this.http.post<ISearchResponse>(_baseUrl, bodyString, { headers });
    }

    getPostpaidDetails(id: number): Observable<IPostpaidDetailsResponse> {
        const _baseUrl = `${this.baseUrl}/api/Ooredoo/SalesPoint/v2.0/GetMobilePostpaidRequestByID/${id}`;
        const headers = this.apiHeader.getAuthHeaders();
        return this.http.get<IPostpaidDetailsResponse>(_baseUrl, { headers, withCredentials: true });
    }

    getPrepaidDetails(id: number): Observable<IPrepaidDetailsResponse> {
        const _baseUrl = `${this.baseUrl}/api/Ooredoo/SalesPoint/v2.0/GetMobilePrepaidRequestByID/${id}`;
        const headers = this.apiHeader.getAuthHeaders();
        return this.http.get<IPrepaidDetailsResponse>(_baseUrl, { headers, withCredentials: true });
    }

    fulfillMobilePrepaid(body: IFulfillMobilePrepaidRequest): Observable<IFulfillMobilePrepaidResponse> {
        const _baseUrl = `${this.baseUrl}/api/Ooredoo/SalesPoint/v2.0/FulfillMobilePrepaidRequest`;
        const headers = this.apiHeader.postAuthHeaders();
        const string = JSON.stringify(body)
        return this.http.post<IFulfillMobilePrepaidResponse>(_baseUrl, string, { headers: headers, withCredentials: true })
    }

    fulfillMobilePostpaid(body: IFulfillMobilePostpaidRequest): Observable<IFulfillMobilePostpaidResponse> {
        const _baseUrl = `${this.baseUrl}/api/Ooredoo/SalesPoint/v2.0/FulfillMobilePostpaidRequest`;
        const headers = this.apiHeader.postAuthHeaders();
        const string = JSON.stringify(body)
        return this.http.post<IFulfillMobilePostpaidResponse>(_baseUrl, string, { headers: headers, withCredentials: true })
    }

    getSimReplacementDetails(id: number): Observable<ISimReplacementDetailsResponse> {
        const _baseUrl = `${this.baseUrl}/api/Ooredoo/SalesPoint/v2.0/GetSimReplacementRequestDetailsByID/${id}`;
        const headers = this.apiHeader.getAuthHeaders();
        return this.http.get<ISimReplacementDetailsResponse>(_baseUrl, { headers, withCredentials: true });
    }

    getPostpaidPortInDetails(id: number): Observable<IPostpaidPortInResponse> {
        const _baseUrl = `${this.baseUrl}/api/Ooredoo/SalesPoint/v2.0/GetPostpaidPortInRequestByID/${id}`;
        const headers = this.apiHeader.getAuthHeaders();
        return this.http.get<IPostpaidPortInResponse>(_baseUrl, { headers, withCredentials: true });
    }

    getPrepaidPortInDetails(id: number): Observable<IPrepaidPortInResponse> {
        const _baseUrl = `${this.baseUrl}/api/Ooredoo/SalesPoint/v2.0/GetPrepaidPortInRequestByID/${id}`;
        const headers = this.apiHeader.getAuthHeaders();
        return this.http.get<IPrepaidPortInResponse>(_baseUrl, { headers, withCredentials: true });
    }
}