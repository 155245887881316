
import { HttpEvent, HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthApiService } from './auth-api.service';
import { EncryptionService } from './encryption.service';
import { selectLoginResponse } from '../../../../store/auth/selectors.auth';
import * as authActions from './../../../../store/auth/actions.auth';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const router = inject(Router);
  const encryptionService = inject(EncryptionService);
  const authApi = inject(AuthApiService);

  const isRefreshTokenRequest = req.url.includes("RefreshToken");

  return store.select(selectLoginResponse).pipe(
    take(1),
    switchMap((loginResponse) => {
      const token = loginResponse?.data?.token;

      const authReq = token && !isRefreshTokenRequest
        ? req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        })
        : req;

      return next(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 403) {
            return handle401Error(authReq, next, authApi, encryptionService, store, router);
          }

          return throwError(() => error);
        })
      );
    })
  );
};


function handle401Error(
  req: HttpRequest<any>,
  next: HttpHandlerFn,
  authApi: AuthApiService,
  encryptionService: EncryptionService,
  store: Store,
  router: Router
): Observable<HttpEvent<any>> {
  const encryptedRefreshToken = localStorage.getItem('refresh_token');
  if (!encryptedRefreshToken) {
    // If no refresh token is available, return an error
    return throwError(() => new Error('No refresh token available'));
  }

  return authApi.refreshToken({ refresh_token: encryptedRefreshToken }).pipe(
    switchMap((res) => {
      // Encrypt and store the new refresh token
      return encryptionService.encryptToken(res.data.refresh_token).pipe(
        switchMap(({ encryptedToken }) => {
          localStorage.setItem('refresh_token', encryptedToken);

          // Dispatch the updated token to the store
          store.dispatch(authActions.basicLoginActions.loginSuccess({ loginResponse: res }));
          store.dispatch(authActions.setRefreshTokenAction({ refreshToken: res.data.token }));

          // Clone the request and retry with the new token
          const clonedReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${res.data.token}`,
            },
          });
          return next(clonedReq);
        })
      );
    }),
    catchError((error) => {
      if (error.status === 500 && error.error?.error?.message?.includes("401")) {
        localStorage.removeItem("refresh_token");
        router.navigate(['login']);
      }
      store.dispatch(authActions.refreshTokenActions.refreshTokenFailure({ error }));
      return throwError(() => error);
    })
  );
}