import { Component, Input, OnInit } from '@angular/core';
import { IContactInfo } from '../../interfaces/contact-info.interface';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { LetDirective } from '@ngrx/component';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-contact-info',
  standalone: true,
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
  imports: [CommonModule, InputTextModule, LetDirective]
})
export class ContactInfoComponent extends BaseComponent {
  @Input() contactInfo!: IContactInfo;

  constructor(override store: Store) {
    super(store)
  }


}
