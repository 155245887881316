import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { take } from 'rxjs';
import { basicLoginActions } from '../../../../../store/auth/actions.auth';
import { BaseComponent } from '../../../../shared/components/base.component';
import { ILoginRequest } from '../../interfaces/auth.interface';
import { EncryptionService } from '../../services/encryption.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    LetDirective
  ],
  encapsulation: ViewEncapsulation.None
})
export class LoginPage extends BaseComponent {
  loginForm!: FormGroup

  constructor(
    private readonly fb: FormBuilder,
    private readonly encryptionService: EncryptionService,
    override store: Store,
  ) {
    super(store)
    this.loginForm = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  submit() {
    const value = this.loginForm.value;

    this.encryptionService.encryptLoginParams(value.login, value.password).pipe(take(1)).subscribe(x => {
      const body: ILoginRequest = {
        username: x.encryptedLogin,
        password: x.encryptedPassword
      }
      this.store.dispatch(basicLoginActions.tryLogin({ body }))
    })
  }
}