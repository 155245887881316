<ng-container *ngrxLet="routerParams$"></ng-container>
<ng-container *ngrxLet="selectorPostpaidDetailsData$ as data">
    <div class="row fulfilment-prepaid-page">
        <div class="col-12 col-md-7 side-left">
            <app-customer-info [information]="data.customer_info"></app-customer-info>
            <app-sim-info [simInfo]="data.sim_info" (iccidChange)="handleIccidChange($event)"></app-sim-info>
            <app-plan-info [planInfo]="data.plan_info"></app-plan-info>
            <app-address-details [addressInfo]="data.address_details"></app-address-details>
            <app-add-contact [contactInfo]="data.contact_info"></app-add-contact>
            <app-terms-and-conditions></app-terms-and-conditions>
            <app-signature-pad></app-signature-pad>
            <app-contract (showContract)="handleContractAction(true, data)"></app-contract>
        </div>
        <div class="col-12 col-md-5">
            <app-details-document [documents]="data.documents?? {}"></app-details-document>
        </div>
        <app-footer-fulfilment class="sticky-footer" (submit)="onSubmit(data)"></app-footer-fulfilment>
    </div>
</ng-container>