import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../../shared/components/base.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { LetDirective } from '@ngrx/component';
import { LookupsExtractPipe } from "../../../../shared/pipes/lookups-extract.pipe";
import { ISystemLookupResponse, LookupArray, SystemLookup } from "../../../../shared/interfaces/systemLookups.interface";
import { selectSystemLookups } from '../../../../../store/base/selectors.base';
import { filter, map } from 'rxjs/operators';
import { IAddressDetail } from '../../interfaces/address-detail.interface';

@Component({
  selector: 'app-address-details',
  standalone: true,
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss'],
  imports: [
    CommonModule,
    LetDirective,
    InputTextModule,
    DropdownModule,
    FormsModule,
    LookupsExtractPipe
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddressDetailsComponent extends BaseComponent {
  @Input() addressInfo!: IAddressDetail;
  @Output() addressInfoChange = new EventEmitter<IAddressDetail>();

  selectedCity: LookupArray | undefined;
  selectedRegion: LookupArray | undefined;
  selectedWilaya: LookupArray | undefined;
  selectedLocality: LookupArray | undefined;

  cities: LookupArray[] = [];
  regions: LookupArray[] = [];
  wilayas: LookupArray[] = [];
  localities: LookupArray[] = [];

  constructor(override store: Store) {
    super(store);

    this.lookups$ = this.store.select(selectSystemLookups).pipe(
      filter((response): response is ISystemLookupResponse => response !== null),
      map((response) => {
        const cityLookup = response.system_lookups.find((lookup: SystemLookup) => lookup.id === 'city_types');
        this.cities = cityLookup ? cityLookup.array : [];
        this.selectedCity = this.cities.find(city => city.name === this.addressInfo.city_description);

        const regionLookup = response.system_lookups.find((lookup: SystemLookup) => lookup.id === 'region_types');
        this.regions = regionLookup ? regionLookup.array : [];
        this.selectedRegion = this.regions.find(regon => regon.name === this.addressInfo.region_description);

        this.wilayas = this.selectedRegion?.childs?.array || [];
        this.selectedWilaya = this.wilayas.find(wilaya => wilaya.name === this.addressInfo.wilaya_description);

        this.localities = this.selectedWilaya?.childs?.array || [];
        this.selectedLocality = this.localities.find(locality => locality.name === this.addressInfo.locality_description);

        return response;
      })
    );
    this.lookups$.subscribe();
  }

  onCityChange(event: any) {
    this.selectedRegion = undefined;
    this.selectedWilaya = undefined;
    this.selectedLocality = undefined;

    this.addressInfo.city_description = event.value.name;
    this.addressInfo.city_id = event.value.key;
    this.emitAddressInfoChange();
  }

  onRegionChange(event: any) {
    this.selectedWilaya = undefined;
    this.selectedLocality = undefined;
    this.wilayas = [];

    this.addressInfo.region_description = event.value.name;
    this.addressInfo.region_id = event.value.key;
    this.wilayas = this.regions.find(region => region.name === event.value.name)?.childs?.array || [];
    this.emitAddressInfoChange();
  }

  onWilayaChange(event: any) {
    this.selectedLocality = undefined;
    this.localities = [];

    this.addressInfo.wilaya_description = event.value.name;
    this.addressInfo.wilaya_id = event.value.key;
    this.localities = this.wilayas.find(wilaya => wilaya.name === event.value.name)?.childs?.array || [];
    this.emitAddressInfoChange();
  }

  onLocalityChange(event: any) {
    this.addressInfo.locality_description = event.value.name;
    this.addressInfo.locality_id = event.value.key;

    this.emitAddressInfoChange();
  }

  emitAddressInfoChange() {
    this.addressInfoChange.emit(this.addressInfo);
  }

  onInputChange(event: any, field: keyof IAddressDetail) {
    this.addressInfo[field] = event.target.value;
    this.emitAddressInfoChange();
  }
}