<ng-container *ngrxLet="translation$ as tr">
  <ng-container *ngrxLet="lookups$ as lookups">
    <div class="search-container">
      <p class="body-medium-l m-0">Digital fulfilment</p>
      <div class="search-content-container">
        <div class="search-content">
          <div class="title">
            <p class="body-medium-l">Search Customer</p>
          </div>
          <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="searchForm">
            <div class="tabs w-100">
              <p-tabView (onChange)="onTabChange($event)">
                <p-tabPanel header="ID Type" class="body-medium-s">
                  <div class="id-type-tabs">
                    <div class="id-type-input">
                      <label class="body-regular-m" for="id_type">ID type</label>
                      <div id="id_type">
                        <p-dropdown [options]="lookups?.system_lookups | extractLookup: 'id_types_ooredoo'"
                          formControlName="id_type" optionLabel="name" placeholder="Select ID type"></p-dropdown>
                      </div>
                      <!-- Validation message for ID Type -->
                      <div *ngIf="searchForm.get('id_type')?.invalid && searchForm.get('id_type')?.touched"
                        class="error-message">
                        ID Type is required.
                      </div>
                    </div>

                    <div class="id-number-input">
                      <label class="body-regular-m" for="id_number">ID number</label>
                      <input type="text" pInputText id="id_number" formControlName="id_number"
                        placeholder="Enter ID number" />
                      <!-- Validation message for ID Number -->
                      <div *ngIf="searchForm.get('id_number')?.invalid && searchForm.get('id_number')?.touched"
                        class="error-message">
                        ID Number is required.
                      </div>
                    </div>

                  </div>
                </p-tabPanel>
                <p-tabPanel header="MSISDN" class="body-medium-s">
                  <div class="msisdn-tabs">
                    <div class="msisdn-input">
                      <label class="body-regular-m" for="msisdn">MSISDN</label>
                      <input type="text" pInputText id="msisdn" formControlName="msisdn" placeholder="Enter MSISDN" />
                      <!-- Validation message for MSISDN -->
                      <div *ngIf="searchForm.get('msisdn')?.invalid && searchForm.get('msisdn')?.touched"
                        class="error-message">
                        MSISDN is required.
                      </div>
                    </div>
                  </div>

                </p-tabPanel>
              </p-tabView>
            </div>
            <div class="btn-search">
              <p-button class="w-100 p-button-lg" label="Search" type="submit"></p-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>