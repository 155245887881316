<ng-container *ngrxLet="translation$ as tr">
  <div class="terms-and-conditions-container">
    <div class="terms-and-conditions-information">
      <div class="section-title body-medium-l">{{tr?.['terms_and_conditions_label']}}</div>
      <div class="box-checkbox">
        <p-checkbox [(ngModel)]="checked" [binary]="true" inputId="terms_and_conditions" />
        <label (click)="openModal()" class="body-regular-m w-100">
          {{tr?.['terms_and_conditions_label']}}
        </label>
      </div>
      @if(showValidationMessage){
      <div class="validatiom-msg">
        <div class="box-icon"><i class="fa-light fa-circle-info"></i></div>
        <div class="text-validation body-medium-m">
          Please agree on terms and conditions
        </div>
      </div>
      }
    </div>
  </div>
</ng-container>