import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'base64ToThumbnail',
  standalone: true
})
export class Base64ToThumbnailPipe implements PipeTransform {

  transform(base64: string, maxWidth: number = 150): Observable<string> {
    return new Observable<string>((observer) => {
      const img = new Image();
      img.src = base64;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleSize = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleSize;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const thumbnail = canvas.toDataURL('image/jpeg');
          observer.next(thumbnail);
          observer.complete();
        }
      };

      img.onerror = (error) => {
        observer.error(error);
      };
    });
  }

}
