import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Store } from '@ngrx/store';
import { LetDirective } from '@ngrx/component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-contract-modal',
  standalone: true,
  templateUrl: './contract-modal.component.html',
  styleUrls: ['./contract-modal.component.scss'],
  imports: [CommonModule, PdfViewerModule, LetDirective, ButtonModule]
})
export class ContractModalComponent extends BaseComponent {
  @Input() responseData!: any;

  constructor(public activeModal: NgbActiveModal, override store: Store) {
    super(store);
  }

  onClose(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.activeModal.close('Close click');
      event.preventDefault();
    }
  }
}
