import { CommonModule } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs';
import { prepaidDetailsActions } from '../../../../../store/fulfilment/actions.fulfilment';
import { selectPrepaidDetails } from '../../../../../store/fulfilment/selectors.fulfilment';
import { ContactInfoComponent } from '../../components/contact-info/contact-info.component';
import { CustomerInfoComponent } from '../../components/customer-info/customer-info.component';
import { DetailsDocumentComponent } from '../../components/details-document/details-document.component';
import { FulfilmentInformationComponent } from '../../components/fulfilment-information/fulfilment-information.component';
import { PlanInfoComponent } from '../../components/plan-info/plan-info.component';
import { SimInfoComponent } from '../../components/sim-info/sim-info.component';
import { IOrganizePrepaidDetailsResponse, IPrepaidDetailsResponse } from '../../interfaces/prepaid-details.interface';
import { TermsAndConditionsComponent } from '../../components/terms-and-conditions/terms-and-conditions.component';
import { CustomerSignatureComponent } from '../../components/customer-signature/customer-signature.component';
import { ButtonModule } from 'primeng/button';
import { FooterFulfilmentComponent } from '../../footer-fulfilment/footer-fulfilment.component';
import { SignaturePadComponent } from '../../components/signature-pad/signature-pad.component';
import { IFulfillMobilePrepaidRequest } from '../../interfaces/fulfill-mobile-prepaid.interface';
import { FulfillmentApiService } from '../../services/fulfilment-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertPopupComponent } from '../../../../shared/components/alert-popup/alert-popup.component';
import { IPlanInfo, IPlanSpecification } from '../../interfaces/plan-info.interface';

@Component({
  selector: 'app-postpaid',
  templateUrl: './prepaid.page.html',
  styleUrls: ['./prepaid.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    ContactInfoComponent,
    SimInfoComponent,
    FulfilmentInformationComponent,
    CustomerInfoComponent,
    PlanInfoComponent,
    DetailsDocumentComponent,
    TermsAndConditionsComponent,
    CustomerSignatureComponent,
    ButtonModule,
    FooterFulfilmentComponent,
    SignaturePadComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class PrepaidPage {
  @ViewChild(TermsAndConditionsComponent) termsAndConditionsComponent!: TermsAndConditionsComponent;

  iccid: string = '';
  searchType: string = '';
  idPrepaid: number = 0;

  routerParams$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    map(id => id ? +id : null),
    tap(id => {
      if (id !== null) {
        this.idPrepaid = id;
        this.store.dispatch(prepaidDetailsActions.getPrepaidDetails({ id }));
      }
    })
  );

  selectorPrepaidDetailsData$ = this.store.select(selectPrepaidDetails).pipe(
    filter(res => res !== null),
    map((res) => this.organizeDetails(res as IPrepaidDetailsResponse))
  )

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private fulfillmentApiService: FulfillmentApiService,
    private modalService: NgbModal
  ) {
    this.route.queryParams.subscribe(params => {
      this.searchType = params['processTypeCode'];
    });
  }

  onSubmit(data: IOrganizePrepaidDetailsResponse) {

    this.termsAndConditionsComponent.validateTerms();

    if (this.termsAndConditionsComponent.checked) {
      const requestData: IFulfillMobilePrepaidRequest = {
        documents: data.documents!,
        search_type: this.searchType,
        id_expiry_date: data.customer_info.id_expiry_date,
        iccid: this.iccid,
        is_existing_customer: true,
        id: this.idPrepaid
      };

      this.fulfillmentApiService.fulfillMobilePrepaid(requestData).subscribe({
        next: (response) => {
          const modalRef = this.modalService.open(AlertPopupComponent, { centered: true });
          modalRef.componentInstance.statusCode = 200;
          modalRef.componentInstance.responseMessage = response.message;
        },
        error: (error) => {
          const modalRef = this.modalService.open(AlertPopupComponent, { centered: true });
          modalRef.componentInstance.statusCode = 400;
          modalRef.componentInstance.responseMessage = error.error.message;
        }
      });
    }
  }

  handleIccidChange(newIccid: string): void {
    this.iccid = newIccid;
  }

  defaultString(value: string | undefined): string {
    return value ?? '';
  }

  defaultNumber(value: string | undefined): string {
    return (value === undefined || value === null || value.trim() === '') ? '0.0' : value;
  }

  organizeDetails(res: IPrepaidDetailsResponse): IOrganizePrepaidDetailsResponse {
    return {
      customer_info: {
        title: this.defaultString(res?.title),
        first_name: this.defaultString(res?.first_name),
        second_name: this.defaultString(res?.second_name),
        middle_name: this.defaultString(res?.middle_name),
        last_name: this.defaultString(res?.last_name),
        id_type: this.defaultString(res?.id_type),
        id_number: this.defaultString(res?.id_number),
        id_expiry_date: this.defaultString(res?.id_expiry_date),
        date_of_birth: this.defaultString(res?.date_of_birth),
        nationality: this.defaultString(res?.nationality),
        gender: this.defaultString(res?.gender),
      },
      contact_info: {
        contact_number: this.defaultString(res?.contact_number),
        email: this.defaultString(res?.email)
      },
      sim_info: {
        msisdn: this.defaultString(res?.msisdn),
        vanity_level: this.defaultString(res?.vanity_level),
        vanity_amount: this.defaultString(res?.vanity_amount),
      },
      plan_info: this.organizePlanDetails(res),
      documents: JSON.parse(JSON.stringify(res.documents)),
    };
  }

  organizePlanDetails(res: IPrepaidDetailsResponse): IPlanInfo {
    return {
      is_paid: res.is_paid,
      recharge_amount: this.defaultNumber(res.plan_details?.recharge_amount),
      total_due_amount: this.defaultNumber(res.plan_details?.total_due_amount),
      validate: this.defaultString(res.plan_details?.validate),
      flexi_mins: this.defaultString(res.plan_details?.flexi_mins),
      cross_mins: this.defaultString(res.plan_details?.cross_mins),
      valid_for: this.defaultString(res.plan_details?.valid_for),
      price: {
        amount: this.defaultString(res.plan_details?.price?.amount),
        amount_inclusive_vat: this.defaultString(res.plan_details?.price?.amount_inclusive_vat),
        vat_amount: this.defaultString(res.plan_details?.price?.vat_amount),
        vat_percentage: this.defaultString(res.plan_details?.price?.vat_percentage),
        vat_type: this.defaultString(res.plan_details?.price?.vat_type),
      },
      name: this.defaultString(res.plan_details?.name),
      data: this.defaultString(res.plan_details?.data),
      time: this.defaultString(res.plan_details?.time),
      plan_specifications: (res.plan_details?.plan_specifications || []).map((spec: IPlanSpecification) => ({
        quantity_unit: this.defaultString(spec.quantity_unit),
        name: this.defaultString(spec.name),
        value: this.defaultString(spec.value),
        class_name: this.defaultString(spec.class_name),
      }))
    };
  }
}

