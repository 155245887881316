import { Injectable } from '@angular/core';
import { ApiHeaderService } from '../../../shared/services/api-headers.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  sessionId: string = ''
  constructor(
    private readonly routers: Router,
    private readonly apiHeaderService: ApiHeaderService,
  ) { }

  // initialize() {
  //   const refreshToken = localStorage.getItem('refresh_token');
  //   if (refreshToken) {
  //     this.apiHeaderService.setToken(refreshToken);
  //   }
  // }

  successLogin(encryptedToken: string, refreshToken: string) {
    //this.apiHeaderService.setToken(refreshToken);
    this.routers.navigate(['home'])
  }

  logout() {
    localStorage.removeItem('refresh_token');
    this.routers.navigate(['login']);
  }

  getUserName() {
    // TO-DO get from API
    return "First Name"
  }
}