<ng-container *ngIf="navigationEnd$ | async"></ng-container>
<ng-container *ngrxLet="navigationEnd$"></ng-container>

<div class="menu">
  @if(drawer.opened){
  <div class="logo" (click)="navigateToLandingPage()">
    <img src="../../../assets/images/Logo.png" />
  </div>
  <ul class="menu-list">
    @for (item of siteNavList; track $index) {
    <li class="menu-item" [class.active]="selectedRoute === item.link" (click)="onMenuItemClick(item)">
      <span class="icon" [innerHTML]="item.icon"></span>
      <span class="item-title body-regular-l">{{item.name}}</span>
    </li>
    }
  </ul>
  }@else {
  <div class="logo" (click)="navigateToLandingPage()">
    <img src="../../../assets/images/Logo-icon.png" />
  </div>
  <ul class="menu-list-small">
    @for (item of siteNavList; track $index) {
    <li class="menu-item-small" [class.active]="selectedRoute === item.link" (click)="onMenuItemClick(item)">
      <span class="icon" [innerHTML]="item.icon"></span>
    </li>
    }
  </ul>
  }
</div>