import { createAction, createActionGroup, props } from "@ngrx/store";
import { IPostpaidDetailsResponse } from "../../app/features/fulfilment/interfaces/postpaid-details.interface";
import { IPrepaidDetailsResponse } from "../../app/features/fulfilment/interfaces/prepaid-details.interface";
import { ISimReplacementDetailsResponse } from "../../app/features/fulfilment/interfaces/sim-replacement-details.interface";
import { IPrepaidPortInResponse } from "../../app/features/fulfilment/interfaces/prepaid-portIn-details.interface";
import { IPostpaidPortInResponse } from "../../app/features/fulfilment/interfaces/postpaid-portIn-details.interface";
import { IUpdateLocalStatePayload } from "../interfaces/updateLocalState.interface";
import { FulfillmentState } from "./reducer.fulfilment";
import { IFulfillMobilePrepaidRequest, IFulfillMobilePrepaidResponse } from "../../app/features/fulfilment/interfaces/fulfill-mobile-prepaid.interface";
import { IFulfillMobilePostpaidRequest, IFulfillMobilePostpaidResponse } from "../../app/features/fulfilment/interfaces/fulfill-mobile-postpaid.interface";

export const updateLocalFulfilmentState = createAction(
  '[FULFILLMENT] update local fulfilment state',
  props<IUpdateLocalStatePayload<FulfillmentState>>()
)

export const postpaidDetailsActions = createActionGroup({
  source: 'FULFILLMENT',
  events: {
    'get postpaid details': props<{ id: number }>(),
    'get postpaid details success': props<{ response: IPostpaidDetailsResponse }>(),
    'get postpaid details failure': props<{ error: Error }>(),
  }
});

export const prepaidDetailsActions = createActionGroup({
  source: 'FULFILLMENT',
  events: {
    'get prepaid details': props<{ id: number }>(),
    'get prepaid details success': props<{ response: IPrepaidDetailsResponse }>(),
    'get prepaid details failure': props<{ error: Error }>(),
  }
});

export const fulfillMobilePrepaidActions = createActionGroup({
  source: 'FULFILLMENT',
  events: {
    'fulfill mobile prepaid': props<{ request: IFulfillMobilePrepaidRequest }>(),
    'fulfill mobile prepaid success': props<{ response: IFulfillMobilePrepaidResponse }>(),
    'fulfill mobile prepaid failure': props<{ error: Error }>(),
  }
});
export const fulfillMobilePostpaidActions = createActionGroup({
  source: 'FULFILLMENT',
  events: {
    'fulfill mobile postpaid': props<{ request: IFulfillMobilePostpaidRequest }>(),
    'fulfill mobile postpaid success': props<{ response: IFulfillMobilePostpaidResponse }>(),
    'fulfill mobile postpaid failure': props<{ error: Error }>(),
  }
});

export const simReplacementDetailsActions = createActionGroup({
  source: 'FULFILLMENT',
  events: {
    'get sim replacement details': props<{ id: number }>(),
    'get sim replacement details success': props<{ response: ISimReplacementDetailsResponse }>(),
    'get sim replacement details failure': props<{ error: Error }>(),
  }
});

export const postpaidPortInActions = createActionGroup({
  source: 'FULFILLMENT',
  events: {
    'get postpaid port in details': props<{ id: number }>(),
    'get postpaid port in details success': props<{ response: IPostpaidPortInResponse }>(),
    'get postpaid port in details failure': props<{ error: Error }>(),
  }
});

export const prepaidPortInActions = createActionGroup({
  source: 'FULFILLMENT',
  events: {
    'get prepaid port in details': props<{ id: number }>(),
    'get prepaid port in details success': props<{ response: IPrepaidPortInResponse }>(),
    'get prepaid port in details failure': props<{ error: Error }>(),
  }
});

export const updateSignatureInDocumentDetails = createAction(
  '[FULFILLMENT] update signature in document details',
  props<{ signatureBase64: string }>()
)

export const setImageBase64Data = createAction(
  '[FULFILLMENT] Set Image Base64 Data',
  props<{ imageBase64: string }>()
);
