<ng-container *ngrxLet="translation$ as tr">
  <div class="sim-details">
    <div class="sim-information">
      <div class="section-title body-medium-l">{{tr?.['sim_information_label']}}</div>
      <div class="items-from">
        @if (simInfo.msisdn.length) {
        <div class="form-item">
          <label for="">{{tr?.['msisdn_label_text_field']}}</label>
          <input type="text" pInputText [disabled]="true" [value]="simInfo.msisdn">
        </div>
        }
        <div class="other-form-items">
          @if (simInfo.vanity_level.length) {
          <div class="form-item">
            <label for="">Vanity</label>
            <input type="text" pInputText [disabled]="true" [value]="simInfo.vanity_level">
          </div>
          }
          @if (simInfo.vanity_amount.length) {
          <div class="form-item">
            <label for="">{{tr?.['vanity_amount_label']}}</label>
            <input type="text" pInputText [disabled]="true" [value]="simInfo.vanity_amount">
          </div>
          }
        </div>
        <div class="form-item">
          <label for="">{{tr?.['iccid_label']}}</label>
          <div class="iccid-input-container">
            <input type="text" pInputText (input)="handleIccidInput($event)" required>
            <div class="box-icon">
              <i class="fa-regular fa-barcode-read"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>